@import "../styles/core/utils";

.classroom-grade {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
}

.classroom-wrap {
  padding: 0.266667rem;
}

.classroom-grid {
  width: 100%;
  margin-bottom: 0.266667rem;
  overflow: hidden;
}

.classroom-header {
  width: 100%;
  min-height: 3.28rem;
  padding: 0.533333rem 0.213333rem 0 0.533333rem;
  border-radius: 0.1rem 0.1rem 0 0;
  .class-title {
    display: inline-block;
    font-size: 0.586667rem;
    margin-left: 0.266667rem;
    color: #FFFFFF;
  }
  .dot-toggle i {
    color: #FFFFFF;
  }
}

.theme-1 {
  .classroom-header {
    background-image: url("../images/classroom/class_bg_1@2x.png");
    background-size: cover;
  }
  .homework-type {
    background: #FF7571;
  }
  .subject-sign {
    color: #F54752;
  }
}

.theme-2 {
  .classroom-header {
    background-image: url("../images/classroom/class_bg_2@2x.png");
    background-size: cover;
  }
  .homework-type {
    background: #5DCBFA;
  }
  .subject-sign {
    color: #4DB7F6;
  }
}

.theme-3 {
  .classroom-header {
    background-image: url("../images/classroom/class_bg_3@2x.png");
    background-size: cover;
  }
  .homework-type {
    background: #B275DC;
  }
  .subject-sign {
    color: #8C5CD7;
  }
}

.theme-4 {
  .classroom-header {
    background-image: url("../images/classroom/class_bg_4@2x.png");
    background-size: cover;
  }
  .homework-type {
    background: #FFBE66;
  }
  .subject-sign {
    color: #EF9A25;
  }
}

.classroom-detail {
  margin-top: 0.533333rem;
  overflow: hidden;
  p {
    font-size: 0.373333rem;
    color: #FFFFFF;
    margin-bottom: 0.106667rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.classroom-detail-wrap {
  background: #f0f1f6;
  padding-bottom: .133333rem;
}

.classroom-detail-info {
  box-shadow: 0 0.05333rem 0.10667rem 0 rgba(0, 0, 0, 0.1);
  padding: .666667rem .533333rem;
  color: #313451;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .class-header {
    font-size: .533333rem;
    color: #313451;
    font-weight: bold;
    margin-right: .266667rem;
  }
  p {
    font-size: 0.373333rem;
    margin-bottom: 0.106667rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .info-l {
    width: 72%;
  }
  .info-r {
    width: 28%;
    text-align: right;
    vertical-align: middle;
    font-size: .32rem;
    color: #313451;
    cursor: pointer;
  }
}

.classroom-stu-count {
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    margin-left: 0.106667rem;
  }

  .class-header {
    max-width: 65%;
  }
}

.classroom-section {
  padding: 0.4rem 0.266667rem 0.533333rem 0.266667rem;
  background-color: #fff;
  h4 {
    font-size: 0.266667rem;
    color: #9496AB;
  }
}

.classroom-fixbar {
  position: fixed;
  right: 0.533333rem;
  bottom: 1.533333rem;
  z-index: 120;
  .fixbar-icon {
    position: relative;
    overflow: hidden;
    display: block;
    width: 1.173333rem;
    height: 1.173333rem;
    line-height: 1.173333rem;
    border-radius: 50%;
    text-align: center;
    font-size: 0.586667rem;
    color: #ffffff;
    background: #2D8DFF;
    -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    &:hover {
      -webkit-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
      box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)
    }
  }
}

.maskBar-wrap {
  // background-image: url('../images/classroom/details_bg@2x.png');
  background-color: #FFF;
  height: 1.2rem
  // padding: 1.066667rem 0 1.466667rem 0;
}

.problem-filter {
  .icon-filter {
    font-size: 0.586667rem;
    color: #FFFFFF;
  }
}

.subject-sign {
  display: inline-block;
  width: 0.8rem;
  min-width: 0.8rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  vertical-align: middle;
  background: #FFFFFF;
  border-radius: 50%;
  font-size: 0.48rem;
  color: #FFFFFF;
}

.subject-short {
  display: block;
  width: 1.066667rem;
  height: 1.066667rem;
  line-height: 1.066667rem;
  text-align: center;
  font-size: 0.48rem;
  color: #FFFFFF;
  border-radius: 50%;
}

.theme-1 {
  .subject-short {
    background-image: linear-gradient(0deg, #FF4669 0%, #FF8A74 100%);
  }
}

.reviewed-status {
  font-size: 0.373333rem;
  color: #313451;
  margin-right: 0.16rem;
  line-height: 1;
  strong {
    font-weight: normal;
    font-size: 0.373333rem;
    color: #313451;
    vertical-align: middle;
  }
  i {
    font-size: 0.426667rem;
    margin-right: 0.133333rem;
  }
  .icon-success {
    color: #71C90F;
  }
  .icon-warning {
    color: #ED9300;
  }
  .icon-danger {
    color: #D0011B;
  }
  .icon-unsubmit {
    color: #02CBD5;
  }
  .icon-unreviewed {
    color: #2480EF;
  }
  .icon-reviewed {
    color: #71C90F;
  }
  .danger {
    color: #D0011B;
  }
  .success {
    color: #71C90F;
  }
  .icon-uncomplete {
    color: #ED9300;
  }
}

.tag-wrap {
  display: inline-block;
  min-width: 2.0rem;
  height: 0.533333rem;
  line-height: 0.533333rem;
  font-size: 0.32rem;
  color: #FFFFFF;
  padding: 0 0.213333rem 0 0.48rem;
  vertical-align: middle;
  border-radius: 0.026667rem;
  background: #8E79D8 url("../images/tag_bg@2x.png") no-repeat left;
  background-size: 0.32rem 0.533333rem;
  border: none;
}

.save-homework-list {
  background-color: #FFFFFF;
  .list-adorn-group {
    padding: 0 0.533333rem;
  }
  .save-homework-title {
    height: 1.333rem;
    width: 100%;
  }
  .save-homework-name {
    height: 1.333rem;
    line-height: 1.333rem;
    display: inline-block;
    width: 90%;
  }
  .delet-homework-icon {
    vertical-align: text-top;
    font-size: 14/37.533+rem;
    line-height: 18/37.533+rem;
    width: 18/37.533+rem;
    height: 18/37.533+rem;
    text-align: center;
    background: red;
    margin-right: 8/37.533+rem;
    border-radius: 50%;
    color: #fff;
  }
  .time-wrap {
    text-align: center;
    font-size: 0.32rem;
    color: #9496AB;
  }
}

.save-type {
  padding-left: 0.533333rem;
}

/*班级管理*/
.class-manage-header {
  padding: 0.533333rem;
  overflow: hidden;
  h2 {
    font-size: 0.586667rem;
    color: #313451;
    font-weight: 500;
  }
  .class-details {
    margin-top: 0.48rem;
    p {
      font-size: 0.32rem;
      color: #313451;
      margin-right: 0.4rem;
    }
    .btn {
      padding: 0.106667rem 0.266667rem;
      font-size: 0.32rem;
    }
  }
}

.class-student-list {
  width: 100%;
  margin-top: 0.266667rem;
  padding-bottom: 0.533333rem;
  overflow: hidden;
  h2 {
    font-size: 0.373333rem;
    color: #9496AB;
    padding: 0.266667rem 0;
    text-align: center;
  }
  li {
    float: left;
    text-align: center;
    width: 20%;
    font-size: .373333rem;
    margin-top: .266667rem;
    .head-Img {
      width: 1.333333rem;
      height: 1.333333rem;
    }
    p {
      color: #313451;
      margin-top: .266667rem;
      @include ellipsis();
    }
  }
}

.student-details {
  text-align: left;
  padding: 0.533333rem 0 0.533333rem 0.693333rem;
  p {
    font-size: 0.373333rem;
    color: #313451;
    line-height: 0.8rem;
  }
}

// 未设置学科教材的班级
.class-set-container {
  font-size: 0.373333rem;
  color: #303451;
  p {
    margin: 0.266667rem 0;
    button {
      border-radius: 25px;
      padding: 5px 15px;
    }
  }
  ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      margin-right: 0.266667rem;
      margin-bottom: 0.266667rem;
      padding: 0.026667rem 0.133333rem;
      color: #303451;
      height: 0.56rem;
      text-align: center;
      line-height: 0.506666rem;
      font-size: 0.373333rem;
      background: #FFFFFF;
      box-shadow: 0 0 0.213333rem 0 #ADADAD;
      border-radius: 0.106667rem;
    }
  }
}

.relative {
  position: relative;
}

.bg-disabled {
  background: #f6f6f6 !important;
  .mask-delete {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 11;
    opacity: 0.5;
    background: #f6f6f6 !important;
  }
  .homework-refer-time {
    background: #f6f6f6 !important;
  }
}

.mask-after-details {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 21;
  opacity: 0.7;
  background: #f6f6f6 !important;
  color: #D0011B;
  font-size: 18px;
}
