$rate-prefix-cls: 'rc-rate';
$rate-star-color: #F8BE1F;
$font-size-base: 0.346667rem;

.#{$rate-prefix-cls} {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.746667rem;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;

  &-disabled, &-star {
    & > div {
      &:focus {
        outline: 0;
      }
    }
    &:before,
    &-content:before {
      cursor: default;
    }
    &:hover {
      transform: scale(1);
    }
  }

  &-star {
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-right: 0.213333rem;
    position: relative;
    transition: all .3s;
    color: #e9e9e9;
    cursor: pointer;
    line-height: 1.5;
    i {
      font-size: 0.693333rem;
    }
    &-first,
    &-second {
      transition: all .3s;
    }

    &-focused, &:hover {
      transform: scale(1.1);
    }

    &-first {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
    }

    &-half &-first,
    &-half &-second {
      opacity: 1;
    }

    &-half &-first,
    &-full &-second {
      color: $rate-star-color;
    }

    &-half:hover &-first,
    &-full:hover &-second {
      color: tint($rate-star-color,30%);
    }
  }
}
