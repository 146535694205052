.viewer-container {
  position: fixed;
  bottom: 0;
  direction: ltr;
  font-size: 0;
  left: 0;
  line-height: 0;
  overflow: hidden;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .pictures {
    display: block;
    height: auto;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
    img {
      width: 100%;
      display: block;
    }

  }
}

.viewer-canvas {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  img {
    background: #ffffff;
  }
}

.viewer-transition {
  -webkit-transition: all .3s;
  transition: all .3s;
}

.viewer-fade {
  opacity: 0;
}

.viewer-in {
  opacity: 1;
}

.viewer-move {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.viewer-invisible {
  visibility: hidden;
}

.viewer-footer {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 100;
}

.viewer-backdrop {
  background-color: rgba(0, 0, 0, .5);
}

.viewer-toolbar {
  ul {
    display: inline-block;
    margin: 0 auto 5px;
    overflow: hidden;
    padding: 3px 0;
    li {
      background-color: rgba(0, 0, 0, .5);
      border-radius: 50%;
      cursor: pointer;
      float: left;
      height: 30px;
      overflow: hidden;
      -webkit-transition: background-color .15s;
      transition: background-color .15s;
      width: 30px;
      line-height: 30px;
      margin: 0 5px;
      &.tool-square {
        border-radius: 0;
      }
      &.btn-text-item {
        width: auto;
        border-radius: 0;
        .btn {
          border-radius: 0;
          display: block;
          padding: 0 10px;
          line-height: 29px;
        }
      }
      .iconfont {
        color: #ffffff;
        font-size: 18px;
      }
      &.active {
        background-color: #fff;
        .iconfont {
          color: #EC3455;
        }
      }
    }
  }
}

.viewer-close {
  position: absolute;
  height: 40px;
  overflow: hidden;
  right: 0;
  top: 0;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: transparent;
  .iconfont {
    color: #ffffff;
    font-size: 18px;
  }
}

.whiteboard-container {
  background-color: #ffffff;
}

.viewer-whiteboard {
  img {
    width: 100%;
  }
  &.eraser {
    cursor: url('../images/viewer/easer-cursor.png') 8 18, auto
  }
  &.pencil {
    cursor: url('../images/viewer/pencil-cursor.png') 8 18, auto
  }
  &.text {
    cursor: text;
  }
}

.viewer-component {
  position: absolute;
  width: 100%;
  height: 100%;
}

.whiteboard-toolbar {
  background: #F7FBFF;
  height: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .toolbar-left, .toolbar-right {
    display: flex;
    align-items: center;
  }
  .toolbar-left {
    .iconfont {
      color: #08A6F0;
      font-size: 0.64rem;
    }
    .am-button {
      width: 0.9rem;
      height: 1.6rem;
      line-height: 1.6rem;
      border: 0;
      background: transparent;
      border-radius: 0;
      &:before {
        border: 0;
      }
      &.active {
        .iconfont {
          color: #F24F2D;
        }
      }
    }
  }
  .toolbar-right {
    .btn-default {
      color: #2D8DFF;
      border: 1px solid #2D8DFF;
    }
    .am-button {
      width: 1.25rem;
      height: 0.693333rem;
      line-height: 0.693333rem;
      border-radius: 2.666667rem;
      font-size: 0.373333rem;
      margin: 0 0.1rem;
      &.active {
        color: #F24F2D;
        border-color: #F24F2D;
      }
    }
  }
}

.viewer-loading {
  &::after {
    -webkit-animation: viewer-spinner 1s linear infinite;
    animation: viewer-spinner 1s linear infinite;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 40px;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    width: 40px;
    z-index: 1;
  }
  &.viewer-mask {
    background: transparent;
  }
}

.viewer-mask {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFF;
  z-index: 99;
}

@-webkit-keyframes viewer-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes viewer-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
