.billboard-warp {
  padding: 0.533333rem 0.266667rem;
  overflow: hidden;
}

.billboard-title {
  width: 2.506667rem;
  margin: 0 auto;
  i {
    display: block;
    width: 0.373333rem;
    height: 0.08rem;
  }
  .title-left {
    background: url('../images/billboard/title_left_bg@2x.png') no-repeat 0 0;
    background-size: 100% 100%;
  }
  .title-right {
    background: url('../images/billboard/title_right_bg@2x.png') no-repeat 0 0;
    background-size: 100% 100%;
  }
}

.billboard-class-item {
  background: #FFFFFF;
  box-shadow: 0.053333rem 0.106667rem 0.133333rem 0 rgba(148, 150, 171, 0.20);
  border-radius: 0.106667rem;
  margin-top: 0.266667rem;
  .decorate {
    width: 2.133333rem;
    min-width: 2.133333rem;
    height: 2.133333rem;
    background-image: url("../images/billboard/billboard-1@2x.png");
    background-size: cover;
    border-radius: 0.106667rem 0 0 0.106667rem;
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .content {
    padding-left: 0.266667rem;
    font-size: 0.453333rem;
    color: #313451;
  }
}

.billboard-list {
  width: 100%;
  .billboard-item {
    margin-bottom: 0.533333rem;
  }
}

.billboard-author {
  width: 1.066667rem;
  min-width: 1.066667rem;
  text-align: center;
  img {
    width: 0.96rem;
    height: 0.96rem;
  }
  p {
    font-size: 0.32rem;
    color: #9496AB;
    margin-top: 0.16rem;
  }
}

.billboard-content {
  padding-left: 0.533333rem;
  .billboard-inner {
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 0.32rem;
    .content-wrap {
      padding: 0.266667rem;
      .content {
        margin-top: 0.266667rem;
        font-size: 0.32rem;
        color: #9496AB;
        line-height: 0.426667rem;
      }
    }
  }
  .announce {
    background: #2D8DFF;
    padding: 0.373333rem 0;
    text-align: center;
    font-size: 0.373333rem;
    color: #FFFFFF;
  }
  .notify-title {
    font-size: 0.426667rem;
    color: #313451;
    text-align: center;
  }
  .publish-time {
    display: inline-block;
    background: #E1E4E6;
    border-radius: 0.133333rem;
    padding: 0.053333rem 0.24rem;
    margin-top: 0.4rem;
    span {
      font-size: 0.32rem;
      color: #FFFFFF;
    }
  }
}

.billboard-details-wrap {
  padding: 1.066667rem 0.266667rem;
}

.billboard-details {
  position: relative;
  background: #FEFBF4;
  box-shadow: 0 0 0.106667rem 0.026667rem #EEE8DD;
  padding: 1.066667rem 0 0;
  .details-title {
    font-size: 0.426667rem;
    color: #3B3830;
    text-align: center;
    span {
      font-size: 0.293333rem;
      color: #B0A895;
      margin-top: 8px;
    }
  }
  .details-content {
    padding: 0.533333rem;
    line-height: 0.533333rem;
  }
  .details-author {
    position: absolute;
    top: -0.48rem;
    left: 0.533333rem;
    text-align: center;
    img {
      width: 0.96rem;
      height: 0.96rem;
    }
    p {
      font-size: 0.32rem;
      color: #3B3831;
      margin-top: 0.16rem;
    }
  }
}

.billboard-doc-list {
  margin-top: 0.266667rem;
  overflow: hidden;
  .doc-item {
    display: block;
    text-align: right;
    font-size: 0.32rem;
    color: #313451;
    padding: 0.133333rem 0.266667rem 0.133333rem 0;
  }
}

.billboard-plus-icon {
  position: fixed;
  right: .533333rem;
  bottom: .533333rem;
  z-index: 120;
  .fixbar-icon.icon-plus {
    position: relative;
    overflow: hidden;
    display: block;
    width: 1.173333rem;
    height: 1.173333rem;
    line-height: 1.173333rem;
    border-radius: 50%;
    text-align: center;
    font-size: .586667rem;
    color: #fff;
    background: #2d8dff;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
    cursor: pointer;
  }
}

.newBillBoard {
  label {
    color: #313451;
    font-size: 12/37.5+rem;
  }
  .newBillBoard-input {
    margin-top: 15/37.5+rem;
    input {
      color: #313451;
      font-size: 14/37.5+rem;
      border-bottom: 1px solid #bdbfcd;
      background: #f0f1f6;
      line-height: 20/37.5+rem;
      height: 25/37.5+rem;
      margin-bottom: 30/37.5+rem;
      margin-top: 15/37.5+rem;
    }
  }
  .newBillBoard-area {
    textarea {
      width: 100%;
      height: 168/37.533+rem;
      -webkit-border-radius: 4/37.533+rem;
      -moz-border-radius: 4/37.533+rem;
      border-radius: 4/37.533+rem;
      border: 1px solid #bebfcd;
      margin-top: 15/37.5+rem;
      background: #f8f8fa;
    }

  }
  .newBillBoard-footer {
    width: 100%;
    height: 50/37.5+rem;
    position: fixed;
    bottom: 13/37.5+rem;
    button {
      padding-left: 15px;
      padding-right: 15px;
      display: block;
      width: 151/37.5+rem;
      height: 44/37.5+rem;
      margin: auto;
      font-size: 18/37.5+rem;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
  }
  .newBillBoard-affix {
    margin-top: 20/37.5+rem;
    span {
      color: #9496ab;
      font-size: 12/37.5rem;
    }
    .iconfont {
      color: #F5A623;
      margin-right: 6/37.5+rem;
    }
  }
}

