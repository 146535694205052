.lesson-index__list {
  padding: 0 0.4rem 0.4rem;
  background-color: #FFF;
  margin-bottom: 0.133333rem;
  & > h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  .resources-name {
    position: relative;
    display: inline-block;
    width: auto;
    min-width: 2.56rem;
    text-align: center;
    margin-left: 0.133333rem;
    padding: 0 5px;
    & > span {
      position: relative;
      display: block;
      font-size: 0.533333rem;
      line-height: 0.533333rem;
      color: #313451;
      z-index: 10;
      // padding: 0 12px;
    }
  }
  .resources-name:before {
    content: '';
    position: absolute;
    display: inline-block;
    height: 8px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #DBEBFF;
    z-index: 1;
  }
  .tips {
    margin: 0.533333rem 0.133333rem 0 0;
    color: #e5000e;
    font-size: 0.426667rem;
  }
  li {
    width: 33.3%;
    float: left;
    padding: 0.133333rem;
    .outer {
      background: #FFFFFF;
      box-shadow: 0 2px 13px 0 rgba(233,234,239,0.85);
      border-radius: 0.106667rem;
      text-align: center;
      // padding: 0.266667rem 0 0.693333rem 0;
      padding: 0.16rem 0 0.426667rem;
      display: block;
    }
    img {
      width: 2.133333rem;
      //height: 1.066667rem;
    }
    p {
      font-size: 0.373333rem;
      color: #313451;
      margin-top: 0.133333rem;
    }
  }
}

.filter-group {
  h1 {
    margin-bottom: 0.106667rem;
  }
  .icon-bottom-arrow:before {
    transform: rotate(-90deg);
  }
}

.new-test {
  background: #f0f1f6;
  position: relative;
  height: .9rem;
  line-height: .9rem;
  padding: .16rem 0 0;
  .new-test-btn {
    position: fixed;
    right: 0;
    font-size: .426667rem;
    color: #fff;
    line-height: .426667rem;
    background: #fe5300;
    border-radius: 2.666667rem 0 0 2.666667rem;
    padding: 0.186667rem;
    cursor: pointer;
    z-index: 100;
  }
}

.lesson-case-list {
  background: #FFFFFF;
  width: 100%;
  margin-top: 0.266667rem;
  .case-item {
    padding-right: 0.266667rem;
  }
  .file-group {
    padding-left: 0.266667rem;
    .intro {
      font-size: 0.32rem;
      color: #9496AB;
      margin-top: 0.16rem;
    }
  }
  .file-details {
    margin-top: 0.16rem;
    .file-property-item {
      display: flex;
      align-items: center;
      margin-right: 0.4rem;
      &:last-child {
        margin-right: 0;
      }
    }
    span {
      font-size: 0.266667rem;
      color: #9496AB;
      margin-left: 0.053333rem;
    }
    i {
      color: #2D8DFF;
    }
  }
  .file-edit {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    z-index: 10;
    .btn-add, .btn-download {
      background-color: transparent;
      font-size: 0.533333rem;
      color: #2D8DFF;
    }
    .icon-collect {
      background: #8E90F0;
    }
    .btn-collect {
      width: 20px;
      height: 20px;
      line-height: 20px;
      padding: 0;
      vertical-align: middle;
      margin-right: 5px;
      border-radius: 50%;
      &.disabled {
        background: #BABCCE;
      }
    }
    .btn-remove {
      background-color: transparent;
      font-size: 0.533333rem;
      color: #e5000e;
    }
    .btn-reduce {
      background-color: transparent;
      font-size: 0.426667rem;
      color: #e5000e;
    }
    .editor-txt {
      color: #313451;
      line-height: 1;
      &.disabled {
        color: #9496AB;
      }
    }
  }
  .am-list-footer {
    padding: 0;
  }
}

.lesson-chapter {
  .chapter-scroll {
    margin-top: 0;
  }
}

.lesson-preview {
  .lesson-case-list {
    margin-top: 0;
  }
}

.lesson-title {
  height: 1.333333rem;
  padding: 0 .533333rem;
  background-color: #f0f1f6;
  font-size: 0.373333rem;
}

.lesson-zip-title {
  width: 88%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lesson-zip-list {
  li {
    justify-content: space-between;
    border: 1px solid #F0F0F3;
    font-size: .373333rem;
    color: #313451;
    padding: .266667rem;
    .lesson-zip-item {
      width: 80%;
      display: inline-block;
    }
    margin-bottom: .133333rem;
    .btn-add {
      padding: 0;
      background-color: transparent;
      font-size: .533333rem;
      color: #2d8dff;
    }
  }
}
