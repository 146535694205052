
.chapter-scroll {
  background-color: #FFFFFF;
  width: 100%;
  overflow: hidden;
}

.chapter-decorate {
  position: absolute;
  background: #FAFAFA;
  top: 0;
  left: -2.666667rem;
  right: -2.666667rem;
  bottom: 0;
  z-index: 1;
}

.chapter-tree {
  position: relative;
}

.chapter-item {
  position: relative;
  z-index: 10;
  border-top: 0.026667rem solid #f0f1f6;
  .tree-node {
    position: absolute;
    right: 0.266667rem;
    top: 0.346667rem;
    font-size: 0;
    //display: flex;
    //align-items: center;
  }
  .tree-text {
    display: block;
    font-size: 0.373333rem;
    color: #313451;
    padding: 0.373333rem 1.6rem 0.373333rem 0.533333rem;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    word-wrap: normal;
    &.active {
      color: #2D8DFF;
      background: #F1F7FF;
    }
    span {
      vertical-align: middle;
    }
    .icon-folder {
      color: #F5A623;
      margin-right: 0.16rem;
    }
  }
  .hd {
    .tree-text {
      padding-left: 0.8rem;
    }
  }
  i {
    display: inline-block;
    font-size: 0.48rem;
    color: #2D8DFF;
  }
  .problem-count {
    height: 0.533333rem;
    line-height: 0.533333rem;
    font-size: 0.32rem;
    color: #9496AB;
    padding-right: 0.16rem;
  }
  .open-name {
    font-size: 0.373333rem;
    color: #7479A0;
    margin-left: 0.106667rem;
    &.active {
      color: #2D8DFF;
    }
  }
}

.chapter-iconEle {
  margin-top: 13px;
}
