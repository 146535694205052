.home-container {
  padding: 0.4rem;
  background-color: #FFF;
  margin-bottom: 0.133333rem;
  li {
    width: 33.3%;
    float: left;
    padding: 0.133333rem;
    .outer {
      background: #FFFFFF;
      box-shadow: 0 2px 13px 0 rgba(233,234,239,0.85);
      border-radius: 0.106667rem;
      text-align: center;
      // padding: 0.266667rem 0 0.693333rem 0;
      padding: 0.16rem 0 0.426667rem;
      display: block;
    }
    img {
      width: 2.133333rem;
      //height: 1.066667rem;
    }
    p {
      font-size: 0.373333rem;
      color: #313451;
      margin-top: 0.133333rem;
    }
  }
}
