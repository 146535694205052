/* FONT PATH
 * -------------------------- */

/*@font-face {
    font-family: 'IconFont';
    src: url('#{$IconFontPath}/iconfont.eot?v=#{$IconFontVersion}');
    src: url('#{$IconFontPath}/iconfont.eot?#iefix&v=#{$IconFontVersion}') format('embedded-opentype'),
    url('#{$IconFontPath}/iconfont.woff?v=#{$IconFontVersion}') format('woff'),
    url('#{$IconFontPath}/iconfont.ttf?v=#{$IconFontVersion}') format('truetype'),
    url('#{$IconFontPath}/iconfont.svg#fontawesomeregular?v=#{$IconFontVersion}') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

@font-face {
  font-family: 'iconfont';  /* project id 424877 */
  src: url('//at.alicdn.com/t/font_424877_wmp7qha43c9.eot');
  src: url('//at.alicdn.com/t/font_424877_wmp7qha43c9.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_424877_wmp7qha43c9.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_424877_wmp7qha43c9.woff') format('woff'),
  url('//at.alicdn.com/t/font_424877_wmp7qha43c9.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_424877_wmp7qha43c9.svg#iconfont') format('svg');
}
