@import "~antd-mobile/dist/antd-mobile.css";
@import "./core/index";
@import './iconfont/scss/icon-font';
@import '~rmc-calendar/assets/index.css';
@import 'navbar';
@import 'variables';
@import 'paper';
@import 'popup';
@import 'confirm';
@import 'upload';
@import 'dropdown';
@import "homework";
@import 'classroom';
@import 'analysis';
@import 'viewer';
@import 'onlineexam';
@import 'lesson';
@import 'profile';
@import 'login';
@import 'billboard';
@import 'feedback';
@import 'classreport';
@import "improvescore";

#root {
  width: 100%;
}

.app-scroll {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none;
}

.app-scroll-outer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}

.app-full-screen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.bg-dynamic-f0f1f6 {
  background: #F0F1F6;
}

.bg-dynamic-default {
  background: #FFFFFF;
}

.card-group {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0.05rem 0.1rem 0.125rem 0 rgba(161, 161, 161, 0.26);
  border-radius: 0.1rem;
}

.card-group-default {
  width: 100%;
  background: #FFFFFF;
  padding: 0.533333rem;
  overflow: hidden;
  &.noMargin {
    margin: 0;
  }
  &.noPadding {
    padding: 0 0.533333rem;
  }
}

.loading {
  height: 1.066667rem;
  line-height: 1.066667rem;
  text-align: center;
  color: #313451;
  font-size: 0.373333rem;
}


.H-Fifty {
  height: 1.333333rem;
}

.active-animate {
  width: 100%;
  height: 100%;
  background-color: rgba(153, 153, 153, 0.4);
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  &.active {
    -webkit-transition: -webkit-transform .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    transition: -webkit-transform .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    -webkit-transition: transform .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    transition: transform .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible
  }
}

.list-adorn-vertical {
  .list-adorn-group {
    justify-content: center;
    -webkit-justify-content: center;
  }
}

.list-adorn-group {
  position: relative;
  min-height: 1.333333rem;
  border-bottom: 0.026667rem solid #F0F1F6;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #FFFFFF;
  .title {
    font-size: 0.4rem;
    color: #313451;
  }
  .index-name {
    font-size: 0.373333rem;
    color: #9496AB;
    span {
      margin-right: 0.133333rem;
    }
  }
  .left-sign {
    margin-right: 0.266667rem;
  }
  &:last-child {
    border: 0;
  }
  &.hd-checked .title {
    color: #108ee9;
  }
}

.tag-list__main {
  padding: 0 0.533333rem;
}

.modal-overlay {
  position: fixed;
  overflow: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.zIndex-1000 {
  z-index: 1000;
}

.zIndex-1200 {
  z-index: 1200;
}

.zIndex-1400 {
  z-index: 1400;
}

.zIndex-1600 {
  z-index: 1600;
}


.H_navList {
  background-color: #FFFFFF;
  margin-top: 0.266667rem;
  li {
    text-align: center;
    .index-tab {
      position: relative;
      font-size: 0.373333rem;
      color: #313451;
      display: inline-block;
      padding: 0.32rem 0.48rem;
    }
    .active:before {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.053333rem;
      background: #2D8DFF;
      border-radius: 0.053333rem;
    }
  }
}

.filter-group {
  background-color: #FFFFFF;
  padding: 0.24rem 0.533333rem;
  .title {
    display: block;
    font-size: 0.373333rem;
    color: #9496AB;
    margin-right: 0.133333rem;
  }
}

.list-more {
  background-color: #FFFFFF;
  font-size: 0.32rem;
  color: #9496AB;
  text-align: center;
  padding: 0.373333rem;
  i {
    font-size: 0.32rem;
    margin-left: 0.106667rem;
  }
}

.state-success {
  background: #71C90F;
}

.state-warning {
  background: #ED9300;
}

.state-danger {
  background: #D0011B;
}

.state-unsubmit {
  background: #02CBD5;
}

.state-unreviewed {
  background: #2480EF;
}

.state-reviewed {
  background: #71C90F;
}

.success {
  color: #71C90F
}

.warning {
  color: #ED9300;
}

.danger {
  color: #D0011B;
}

.unsubmit {
  color: #2480EF;
}

.reviewed {
  color: #71C90F;
}

.returned {
  color: #02CBD5;
}

.evaluate {
  color: #FA5C29;
}

.error-tip {
  font-size: 0.373333rem;
  color: #FF1300;
}

.default-tip {
  font-size: 0.346667rem;
  color: #9496AB;
  text-align: left;
  p {
    line-height: 0.64rem;
  }
}

.paginate-primary {
  margin-top: 0.133333rem;
  .btn-next {
    background: #F4F9FF;
    border-radius: 2.666667rem;
    font-size: 0.32rem;
    color: #2D8DFF;
    padding: 0.053333rem 0.266667rem;
    line-height: 0.426667rem;
  }
}


.m-t-10 {
  margin-top: 0.266667rem;
}

.footer-fixed-root {
  position: relative;
  min-height: 2.133333rem;
}

.footer-fixed-default {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0.3rem 0;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0 0.053333rem 0.293333rem 0 rgba(148, 150, 171, 0.30);
  z-index: 99;
  li {
    &.col-2 {
      width: 50%;
    }
    &.col-1 {
      width: 100%;
    }
    &.col-3 {
      width: 33.333%;
    }
    &.col-4 {
      width: 25%;
    }
    &.col-5 {
      width: 20%;
    }
    &.col-3-1 {
      width: 44.444%;
    }
    &.col-3-2 {
      width: 55.555%;
    }
  }
  .right-col {
    border-left: 0.026667rem dashed #F0F1F6;
    text-align: center;
    padding: 0.133333rem 0;
  }
  .footer-inner {
    padding: 0 1.6rem;
  }
}

.rmc-calendar {
  .date-picker {
    flex: auto;
    height: 0;
  }
}
