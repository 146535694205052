@import '../variables';

@mixin motion-common ($duration: $animation-duration-base ) {
  animation-duration: $duration;
  animation-fill-mode: both;
}

@mixin motion-common-leave($duration: $animation-duration-base ) {
  animation-duration: $duration;
  animation-fill-mode: both;
}

@mixin make-motion ($className, $keyframeName, $duration: $animation-duration-base ) {
  .#{$className} {
    &-enter, &-appear {
      @include motion-common($duration);
      animation-play-state: paused;
    }
    &-leave {
      @include motion-common-leave($duration);
      animation-play-state: paused;
    }
    &-enter.#{$className}-enter-active, &-appear.#{$className}-appear-active {
      animation-name: "#{$keyframeName}In";
      animation-play-state: running;
    }
    &-leave.#{$className}-leave-active {
      animation-name: "#{$keyframeName}Out";
      animation-play-state: running;
      pointer-events: none;
    }
  }
}
