.iconfont {
  @include icon-font();
}

.icon-delete:before {
  content: $icon-delete;
}

.icon-empty-work:before {
  content: $icon-empty-work;
}

.icon-stu:before {
  content: $icon-stu;
}

.icon-back:before {
  content: $icon-back;
}

.icon-task:before {
  content: $icon-task;
}

.icon-subject:before {
  content: $icon-subject;
}

.icon-link:before {
  content: $icon-link;
}

.icon-file:before {
  content: $icon-file;
}

.icon-warning:before {
  content: $icon-warning;
}

.icon-vertical-more:before {
  content: $icon-vertical-more;
}

.icon-plus:before {
  content: $icon-plus;
}

.icon-publish:before {
  content: $icon-publish;
}

.icon-close:before {
  content: $icon-close;
}

.icon-right-arrow:before {
  content: $icon-right-arrow;
}

.icon-left-arrow:before {
  content: $icon-left-arrow;
}

.icon-bottom-arrow:before {
  content: $icon-bottom-arrow;
}

.icon-offline:before {
  content: $icon-offline;
}

.icon-dropdown:before {
  content: $icon-dropdown;
}

.icon-circle-plus:before {
  content: $icon-circle-plus;
}

.icon-circle-offline:before {
  content: $icon-circle-offline;
}

.icon-filter:before {
  content: $icon-filter;
}

.icon-up-move:before {
  content: $icon-up-move;
}

.icon-down-move:before {
  content: $icon-down-move;
}

.icon-timer:before {
  content: $icon-timer;
}

.icon-see:before {
  content: $icon-see;
}

.icon-download:before {
  content: $icon-download;
}

.icon-comment:before {
  content: $icon-comment;
}

.icon-delete:before {
  content: $icon-delete;
}

.icon-danger:before {
  content: $icon-danger;
}

.icon-unreviewed:before {
  content: $icon-unreviewed;
}

.icon-unsubmit:before {
  content: $icon-unsubmit;
}

.icon-uncomplete:before {
  content: $icon-uncomplete;
}

.icon-return:before {
  content: $icon-return;
}

.icon-success:before {
  content: $icon-success;
}

.icon-menu:before {
  content: $icon-menu;
}

.icon-bofang:before {
  content: $icon-bofang
}

.icon-circle-delete:before {
  content: $icon-circle-delete;
}

.icon-circle-edit:before {
  content: $icon-circle-edit;
}

.icon-collect:before {
  content: $icon-collect;
}

.icon-folder:before {
  content: $icon-folder;
}

.icon-empty-default:before {
  content: $icon-empty-default;
}


.icon-file:before {
  content: $icon-file;
}

.icon-word:before {
  content: $icon-word;
}

.icon-img:before {
  content: $icon-img;
}

.icon-radio:before {
  content: $icon-radio;
}

.icon-video:before {
  content: $icon-video;
}

.icon-ppt:before {
  content: $icon-ppt;
}

.icon-caret-down:before {
  content: $icon-caret-down;
}

.icon-people:before {
  content: $icon-people;
}

.icon-department:before {
  content: $icon-department;
}

.icon-call:before {
  content: $icon-call;
}

.icon-search:before {
  content: $icon-search;
}

.icon-group:before {
  content: $icon-group;
}
