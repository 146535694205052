@import "./core/utils";

.Homework__wrap {
  padding-bottom: 2.133333rem;
}

.Homework__section {
  padding: 0.266667rem 0.533333rem;
}

.Homework__word {
  font-size: 0.4rem;
  color: #313451;
  line-height: 0.64rem;
}

.card-reviewed {
  .exam-type {
    margin: 0 0 0.4rem -0.16rem;
  }
  .problem-title {
    padding-bottom: 0.533333rem;
  }
  .btn-analysis {
    color: #FF6600;
  }
  .exam-score {
    margin-bottom: 0.4rem;
  }
}

.evaluation {
  li.active {
    span {
      color: #FFFFFF;
    }
  }
}

.paper-homework-wrap {
  padding: 0.533333rem 0.533333rem 2.666667rem 0.533333rem;
  overflow: hidden;
  .answer-group {
    margin-top: 0.533333rem;
  }
}

.paper-state {
  overflow: hidden;
  flex-shrink: 0;
  font-size: 0.32rem;
  text-align: center;
  i {
    font-size: 0.373333rem;
    color: #9496AB;
    margin-left: 0.053333rem;
  }
}

.homework-wrap {
  width: 100%;
  padding: 0.266667rem 0.266667rem 0 0.266667rem;
  .homework-item {
    margin-bottom: 0.266667rem;
  }
  &.upwards {
    //margin-top: -1.6rem;
  }
  .save-homework {
    padding: 0.48rem 0.266667rem;
  }
}

.homework-header {
  padding: 0.266667rem 0 0.266667rem 0.266667rem;
  .publish-info {
    margin-left: 0.266667rem;
    h4 {
      font-size: 0.373333rem;
      color: #313451;
      font-weight: 400;
      margin-bottom: 0.106667rem;
    }
    strong, .formatter {
      font-size: 0.32rem;
      color: #9496AB;
      font-weight: 400;
    }
    .refer-time {
      display: block;
      margin-top: 0.106667rem;
    }
  }
}


.homework-section {
  padding: 0.266667rem;
  .title {
    h1 {
      font-size: 0.426667rem;
      color: #313451;
      font-weight: 500;
    }
  }
  .explain {
    margin-top: 0.133333rem;
    font-size: 0.373333rem;
    color: #313451;
    line-height: 1.5;
    @include wrap();
  }
  .task-structure {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: flex-start;
    padding: 0.266667rem 0;
    .task-item {
      margin-right: 0.32rem;
      span {
        font-size: 0.266667rem;
        vertical-align: middle;
      }
      i {
        color: #2D8DFF;
        margin-right: 0.053333rem;
      }
      color: #9496AB;
      &:last-child {
        margin: 0;
      }
    }
  }
  .progress {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: flex-start;
    padding: 0.533333rem 0 0.266667rem 0;
    position: relative;
    .h-s-status {
      padding: 0 0.32rem;
      text-align: center;
    }
    .h-s-status + .h-s-status {
      border-left: 0.026667rem solid #F0F1F6;
    }
    .count {
      font-size: 0.746667rem;
      color: #2D8DFF;
    }
    .status-title {
      font-size: 0.32rem;
      color: #313451;
      margin-top: 0.16rem;
    }
    .corrections {
      position: absolute;
      font-size: .373333rem;
      padding: .08rem .373333rem;
      right: .133333rem;
      color: #fff;
      background: #75DB09;
      border-radius: 2.666667rem;
    }
    .consult {
      position: absolute;
      padding: .08rem .373333rem;
      font-size: .373333rem;
      right: .133333rem;
      color: #fff;
      background: #2D8DFF;
      border-radius: 2.666667rem;
    }
  }
}

.homework-switch {
  width: 100%;
  height: 1.173333rem;
  padding: 0 0.4rem;
  background-color: #FFFFFF;
  box-shadow: 0 0.013333rem 0 0 #D3D7DE;
  &.fixed {
    position: fixed;
    top: 1.173333rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 666;
  }
  .flex {
    height: 100%;
  }
  .switch-left {
    .current {
      margin-left: 0.266667rem;
    }
  }
  .current {
    font-size: 0.373333rem;
    color: #9496AB;
    strong {
      font-size: 0.533333rem;
      color: #2D8DFF;
      font-weight: 400;
    }
    .stu-name {
      font-size: 0.373333rem;
      color: #313451;
      margin-left: 0.213333rem;
    }
  }
  .next, .prev {
    font-size: 0.373333rem;
    color: #9496AB;
    height: 100%;
    i {
      font-size: 0.373333rem;
    }
    span {
      vertical-align: middle;
      line-height: 1;
    }
  }
  .next {
    i {
      margin-left: 0.133333rem;
    }
  }
}

// 布置作业按钮
.homework-arrange-operate {
  display: flex;
  align-items: center;
  background: rgb(240, 241, 246);
  position: relative;
  justify-content: flex-end;
  height: 1.12rem;
  .btn-arrange {
    font-size: 0.426667rem;
    color: #FFF;
    background: #FE5300;
    border-radius: 2.666667rem 0 0 2.666667rem;
    padding: 0.16rem .08rem;
    cursor: pointer;
    z-index: 100;
    i {
      padding: 0 .133333rem;
    }
  }
}


//.edit-homework-wrap{
//  background-color: #f0f1f6;
//}
.homework-edit-group {
  padding: 0 0.533333rem;
  background-color: #FFFFFF;
  margin-bottom: 0.213333rem;
  overflow: hidden;
  .add-content {
    height: 2.666667rem;
    line-height: 2.666667rem;
    text-align: center;
    .index-name {
      font-size: 0.48rem;
      color: #FE5300;
      & > i {
        font-size: 0.586667rem;
      }
    }
  }
  .grade-logo {
    width: 0.96rem;
    height: 0.96rem;
  }
  .grade-name {
    font-size: 0.373333rem;
    color: #313451;
    margin-left: 0.32rem;
    vertical-align: middle;
  }
  .form-area {
    padding: 0.16rem 0;
    border: 0;
    .am-textarea-control {
      textarea {
        min-height: 2.0rem;
      }
    }
  }
  .excellent-detail-container {
    background: #FFF;
  }
}


// 发布作业按钮
.homework-publish-footer {
  height: 1.173333rem;
}

.homework-footer-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.homework-status-btn {
  background: #fff;
  font-size: .373333rem;
  height: .96rem;
  line-height: .96rem;
  padding-left: 0.533333rem;
  color: #313451;
  .am-checkbox {
    margin-right: .16rem;
  }
}

.homework-publish-btn {
  border: 1px solid #f5a622;
  background: #fff;
  color: #f5a622;
  font-size: .48rem;
  line-height: 1.173333rem;
  text-align: center;
  z-index: 100;
  &.btn-publish, .btn-publish {
    background: #F5A622;
    color: #fff;
  }
  .btn {
    display: block;
  }
  .btn-timing {
    border: 1px solid #F5A622;
    border-top: none;
    border-bottom: none;
  }
  .btn-publish {
    flex: 2;
  }
}


.homework-type {
  display: inline-block;
  font-size: 0.266667rem;
  padding: 0.026667rem 0.16rem;
  border-radius: 2.666667rem;
  color: #fff;
  vertical-align: middle;
  margin-right: 0.133333rem;
}

.homework-task-item {
  margin-top: 0.213333rem;
  .homework-title {
    display: inline-block;
    font-size: 0.32rem;
    color: #303451;
    vertical-align: middle;
  }
}

.homework-ratio {
  b {
    font-size: 0.533333rem;
    color: #303451;
    font-weight: 400;
  }
  span {
    font-size: 0.266667rem;
    color: #9496AB;
  }
}

.workBar-nav {
  width: 100%;
  height: 1.2rem;
  background-color: #fff;
  //margin-top: -0.026667rem;
  //background-image: linear-gradient(0deg, #59C1FF 0%, #2D8DFF 88%);
}

.workBar-navList {
  height: 1.333333rem;
  li {
    position: relative;
    width: 50%;
    line-height: 1.2rem;
    text-align: center;
    font-size: 0.373333rem;
    span {
      position: relative;
      color: #313451;
      font-size: 0.4rem;
      display: inline-block;
    }
    //&:nth-child(2) span {
    //  padding: 0.213333rem 0.533333rem;
    //}
    &.active span {
      color: #2D8DFF;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.053333rem;
        background: #2480EF;
      }
    }
  }
}

// 作业编辑
.homework-edit-section {
  padding-bottom: 1.173333rem;
}

.homework-details {
  background-color: #FFFFFF;
  padding: 0.533333rem;
  margin-top: 0.266667rem;
}

.homework-details__header {
  h1 {
    font-size: 0.426667rem;
    color: #313451;
  }
  h4 {
    font-size: 0.32rem;
    color: #9496AB;
    margin: 0.16rem 0 0.213333rem 0;
  }
  p {
    word-break: break-all;
    font-size: 0.373333rem;
    color: #313451;
    line-height: 0.586667rem;
  }
}

.homework-details__status {
  //padding: 0.666667rem 0.373333rem;
  .deadline {
    text-align: center;
    //padding: 0.266667rem 0;
    span {
      font-size: 0.32rem;
      color: #9496AB;
    }
    strong {
      color: #FA5C29;
      font-weight: 400;
      margin-left: 0.133333rem;
    }
  }
  .status-list {
    background: #FFFFFF;
    box-shadow: 0 0 0.533333rem 0 rgba(0, 0, 0, 0.10);
    border-radius: 0.213333rem;
    li {
      padding: 0.373333rem 0;
      text-align: center;
      & > div {
        padding: 0.106667rem 0;
        border-right: 0.026667rem solid #F0F0F0;
      }
      &:last-child > div {
        border-right: 0;
      }
      span {
        font-size: 0.666667rem;
        color: #FA5C29;
      }
      span.submitted {
        color: #2CCB4D;
      }
      p {
        font-size: 0.32rem;
        color: #313451;
        margin-top: 0.16rem;
      }
    }
  }
}


//作业批改
.homework-reviewed__section {
  background-color: #FFF;
  margin-top: 0.266667rem;
  padding: 0.373333rem 0.213333rem 1.866667rem 0.213333rem;
  overflow: hidden;
}

.homework-reviewed-progress {
  margin-bottom: 0.64rem;
  .state-text {
    margin-left: 0.133333rem;
    vertical-align: middle;
    color: #9496AB;
  }
  .review-list {
    width: 100%;
    overflow: hidden;
    margin-top: -0.133333rem;
    li {
      float: left;
      padding: 0 0.08rem;
      color: #313451;
      font-size: .373333rem;
      margin-top: .4rem;
      max-width: 50%;
    }
    .submit-item-inner {
      display: flex;
      align-items: center;
      position: relative;
      padding: 0.133333rem 0.4rem;
      margin: 0 auto;
      background: #F0F1F6;
      border-radius: 0.48rem;
      p {
        line-height: 0.533333rem;
        margin: 0 0.026667rem;
        white-space: nowrap;
      }
      .overdue, .score  {
        font-size: 0.266667rem;
        line-height: 0.266667rem;
        padding: 0.053333rem 0.133333rem;
        color: #FFFFFF;
        background: #8E90F0;
        border-radius: 0.266667rem;
      }
    }
  }
}

.homework-reviewed-footer {
  .comment-submit {
    .icon-comment {
      color: #2D8DFF;
      font-size: 0.533333rem;
    }
    p {
      font-size: 0.32rem;
      color: #313451;
      margin-top: 0.133333rem;
    }
  }
}

.homework-reviewed-answer {
  background-color: #FFF;
  padding: 0 0.533333rem 2.666667rem 0.533333rem;
  .score-wrap {
    font-size: 0.373333rem;
    color: #202020;
  }
}

.homework-reviewed-answer-img {
  li {
    width: 2.773333rem;
    height: 2.773333rem;
    position: relative;
    margin: 0.1rem;
  }
  .pic-item-img {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  img {
    width: 100%;
  }
  .btn-delete {
    width: .5rem;
    height: .5rem;
    background: #fff;
    position: absolute;
    right: -0.133333rem;
    top: -0.133333rem;
    z-index: 10;
    .iconfont {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      color: #2D8DFF;
      font-size: .45rem;
    }
  }
}

.homework-reviewed-score {
  width: 1.066667rem;
  height: 1.066667rem;
  text-align: center;
  line-height: 1.066667rem;
  background: #FECF39;
  border-radius: 50%;
  display: block;
  font-size: 0.586667rem;
  color: #FFFFFF;
  &.checked {
    background: #2D8DFF;
  }
  &.un-reviewed {
    border-radius: 0.053333rem;
    width: 2.4rem;
  }
}

.homework-reviewed-result {
  .complete-info {
    padding: 0.8rem 0.266667rem;
    text-align: center;
    background-color: #ffffff;
    .outer {
      padding: 0.8rem 0;
      border-bottom: 1px solid rgba(148,150,171,.3);
    }
    .icon {
      font-size: 1.6rem;
      color: #71C90F;
    }
    .text {
      font-size: 0.48rem;
      color: #313451;
      margin: 0.266667rem 0 0.4rem 0;
    }
    .score {
      font-size: 0.373333rem;
      color: #001621;
    }
  }
  .reviewed-info {
    padding: 0.266667rem 0.533333rem 0.533333rem 0.533333rem;
    background-color: #ffffff;
  }
}

.homework-deadline {
  margin-top: 0.4rem;
  span {
    display: inline-block;
    border-radius: 2.666667rem;
    font-size: 0.373333rem;
    color: #FD665A;
  }
  .use-time {
    color: #2D8DFF;
  }
}

.homework-adorn {
  width: 100%;
  background: #F0F1F6;
  height: 0.266667rem;
}

.homework-state {
  min-width: 2.0rem;
  border-radius: 2.666667rem;
  font-size: 0.32rem;
  color: #FFFFFF;
  padding: 0.186667rem 0;
  text-align: center;
}

.homework-dashboard {
  .corrected-score {
    color: #fff;
    font-size: .32rem;
    margin: 0.266667rem .133333rem 0;
    border-radius: 2.666667rem;
    padding: .026667rem 0;
    background: #B4B6C5;
  }
}

// 作业参考时间
.homework-refer-time {
  display: flex;
  background: #FFFFFF;
  height: 1.173333rem;
  align-items: center;
  justify-content: flex-start;
  .time-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.266667rem;
    i {
      display: block;
      width: 0.533333rem;
      height: 0.533333rem;
    }
    span, strong {
      font-size: 0.373333rem;
      color: #313451;
      margin-left: 0.133333rem;
    }
    strong {
      font-weight: 400;
    }
  }
  .img-refer {
    background-image: url('../images/homework/refer-time@2x.png');
    background-size: cover;
  }
  .img-complete {
    background-image: url('../images/homework/complete-time@2x.png');
    background-size: cover;
  }
  &.list {
    height: auto;
    margin-top: 0.106667rem;
    i {
      width: 0.4rem;
      height: 0.4rem;
    }
    .time-item {
      padding: 0;
      span {
        font-size: 0.32rem;
        color: #F5A623;
      }
    }
  }
}


.problem-rate-list {
  width: 100%;
  overflow: hidden;
  li {
    position: relative;
    float: left;
    text-align: center;
    width: 20%;
    font-size: 0.373333rem;
    margin-top: 0.266667rem;
    .rate {
      position: relative;
      width: 1.28rem;
      height: 1.28rem;
      line-height: 1.28rem;
      border-radius: 50%;
      border: 0.026667rem solid #2D8DFF;
      text-align: center;
      margin: 0 auto;
      color: #313451;
      font-size: 0.32rem;
      span {
        position: relative;
        z-index: 10;
      }
      .percent-age {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #2D8DFF;
        z-index: 1;
      }
      .not-reviewed {
        position: absolute;
        right: 0;
        top: 0;
        line-height: 1;
        color: #FECF39;
        background-color: #FFFFFF;
        border-radius: 50%;
      }
      .reviewed {
        color: #2D8DFF;
        font-size: 0.48rem;
      }
      &.correct {
        .percent-age {
          background: #64DCDA;
        }
        border-color: #64DCDA;
      }
      &.error {
        .percent-age {
          background: #FD665A;
        }
        border-color: #FD665A;
      }
    }
    .white-color {
      color: #ffffff;
    }
    .corrected {
      position: absolute;
      right: 0.213333rem;
      color: #71C910;
    }
    .img-item {
      width: 1.333333rem;
      height: 1.333333rem;
    }
    p {
      color: #2D8DFF;
      font-weight: 500;
      margin-top: 0.08rem;
    }
    p.gray {
      color: #313451;
      margin-top: 0.266667rem;
    }
    &.active {
      .rate {
        overflow: hidden;
      }
    }
  }
  .score {
    display: block;
    width: 80%;
    background: #4A8DF6;
    border-radius: 2.666667rem;
    font-size: 0.32rem;
    color: #fff !important;
    margin: 0.133333rem auto 0 auto;
    line-height: 0.533333rem;
  }
}

.score-1 {
  background: #7ED321!important;
}

.score-2 {
  background: #4A8DF6!important;
}

.score-3 {
  background: #F58924!important;
}

.score-4 {
  background: #F24F2D!important;
}

.homework-checking-footer {
  height: 1.973333rem;
  .checking-right, .checking-false {
    color: #CCCDD6;
    font-size: 1.2rem;
    &.active {
      color: #2D8DFF;
    }
  }
}

.homework-reviewed-header {
  h1 {
    font-size: 0.373333rem;
    color: #9496AB;
    margin-bottom: 0.16rem;
  }
  .task-item {
    margin-top: 0.213333rem;
    &:first-child {
      margin-top: 0;
    }
    p {
      line-height: 2;
    }
  }
}

// do homework
.homework-task-content {
  padding-bottom: 0.266667rem;
}

.homework-answer-select-group {
  border: 0.026667rem solid #F0F1F6;
  border-radius: 0.16rem;
  margin-top: 0.266667rem;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  overflow: hidden;
  .option-nut {
    background: #F0F1F6;
    border-radius: 0 0.16rem 0.16rem 0;
    min-width: 1.413333rem;
    min-height: 1.413333rem;
    text-align: center;
    line-height: 1.413333rem;
    font-size: 0.533333rem;
    color: #2D8DFF;
  }
  .option-meat {
    padding: 0 0.373333rem;
    font-size: 0.373333rem;
    color: #313451;
  }
  &.checked {
    border-color: #2D8DFF;
    .option-nut {
      background: #3290FE;
      color: #FFFFFF;
    }
  }
}

//课后作业
.teacher-after-homework {
  .homework-wrap.upwards {
    margin-top: 0;
    background: rgb(240, 241, 246);
    padding-top: 0;
  }
}

.student-after-homework {
  .after-homework-item {
    position: relative;
    h2 {
      position: relative;
      font-size: 0.373333rem;
      color: #9496AB;
      width: 2.5rem;
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
      background: rgb(240, 241, 246);
      margin: 0 auto;
      z-index: 10;
    }
    &:before {
      content: '';
      position: absolute;
      height: 0.016667rem;
      background: #DADBE3;
      width: 100%;
      top: 0.5rem;
      left: 0;
      right: 0;
    }
  }
  .homework-wrap.upwards {
    margin-top: -0.3rem;
  }
}

.after-homework-check {
  padding: .266667rem;
}

.after-homework-check-detail {
  margin-top: 3.6rem;
  text-align: center;
  font-size: .426667rem;
  span {
    color: #fff;
    background: rgb(22, 155, 213);
    padding: .133333rem 1.066667rem;
  }
}

.after-homework-check-header {
  font-size: 0.426667rem;
  color: #313451;
  font-weight: 500;
  text-align: center;
  padding: .533333rem 0;
}

.after-homework-check-score {
  display: flex;
  justify-content: flex-start;
  font-size: .4rem;
  color: #313451;
  margin: 0.5rem;
  align-items: center;
  .reviewed-status {
    strong {
      color: #FF2323;
      font-size: .533333rem;
    }
  }
  .check-text {
    min-width: 2.4rem;
  }
}

.after-homework-score-result {
  width: 1.066667rem;
  height: 1.066667rem;
  text-align: center;
  line-height: 1.066667rem;
  border-radius: 50%;
  display: block;
  background: #B4B6C5;
  font-size: .426667rem;
  color: #FFFFFF;
}

.after-homework-remark {
  align-items: flex-start;
  span {
    line-height: 0.533333rem;
  }
}

.homework-reviewed__detail, .homework-edit-section {
  padding-bottom: 0.533333rem;
  .problem-show-wrap {
    padding: 0 0.266667rem;
  }
  .problem-show-header {
    padding: 0.32rem 0;
    font-size: 0.426667rem;
    color: #313451;
    text-align: center;
    //border-bottom: .026667rem solid rgb(222, 223, 229);
  }
  .problem-show-body {
    padding: 0.266667rem 0.266667rem 0.533333rem;
    background: #FFFAF2;
  }
  .problem-word {
    font-size: 0.4rem;
    color: #3B3120;
    letter-spacing: 0;
    text-align: justify;
    line-height: 0.693333rem;
    textarea {
      min-height: 1.333333rem;
    }
    .am-list-item, .am-textarea-control {
      padding: 0;
      background-color: transparent;
    }
  }
  .divider-line {
    height: 0.8rem;
    border-bottom: 0.026667rem dashed #BDBFCD
  }
}
.homework-edit-section {
  .homework-title {
    font-size: 0.426667rem;
    text-align: center;
    color: #313451;
    height: 1.173333rem;
    line-height: 1.173333rem;
    padding: 0 0.266667rem;
  }
  .problem-show-wrap {
    padding: 0.266667rem 0.266667rem 0;
  }
  .review-return-img {
    width: 98px;
    float: right;
    margin: -0.266667rem;
  }
  .problem-show-header {
    text-align: left;
    border-bottom: .026667rem dashed rgb(222, 223, 229);
  }
  .problem-show-body-outer {
    padding: 0.266667rem 0 0.533333rem;
    background: #FFF;
  }
  .problem-show-body {
   margin-top: 0.266667rem;
  }
  .review-header-status {
    font-size: 0.32rem;
    text-align: center;
    width: 1.6rem;
    height: 0.64rem;
    line-height: 0.64rem;
    border-radius: 0 2.666667rem 2.666667rem 0;
    color: #FFF;
    margin-left: -0.266667rem;
    &.overdue {
      background: #FF2323;
      border: 0 solid #FF2323;
    }
    &.unfinished {
      background: #ED9300;
      border: 0 solid #ED9300;
    }
    &.unreviewed {
      background: #2D8DFF;
      border: 0 solid #2D8DFF;
    }
    &.reviewed {
      background: #71C910;
      border: 0 solid #71C910;
    }
  }
  .homework-teacher-review-v2 {
    padding: 10px 10px 20px;
  }
}

// 课后作业样式
.homework-details {
  .problem-show-wrap {
    padding: 0 0.533333rem 0.533333rem;
    .problem-show-header {
      padding: 0.32rem 0;
      font-size: .4rem;
      color: #000;
      border-bottom: .026667rem solid rgb(222, 223, 229);
    }
    .problem-word {
      font-size: 0.4rem;
      padding: 0.4rem 0;
      color: #000000;
      textarea {
        min-height: 1.333333rem;
      }
      .am-list-item, .am-textarea-control {
        padding: 0;
      }
    }
    .problem-img-content {
      display: flex;
      justify-content: space-around;
      flex-flow: wrap;
      .problem-img-content-item {
        width: 1.333333rem;
        height: 1.333333rem;
        margin: .133333rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .problem-img-content-item-delete {
          position: absolute;
          top: -0.133333rem;
          right: -0.133333rem;
          color: #fff;
          border-radius: 50%;
          font-size: .266667rem;
          background: #FF2323;
        }
      }
    }
  }
}

.courseware {
  .problem-show-wrap {
    padding: 0;
  }
}

.after-homework-content-line {
  height: 0.266667rem;
  background: #f0f1f6;
}

.homework-teacher-review {
  padding: 0.533333rem 0.266667rem 0;
  .review-teacher {
    &.review-teacher {
      padding-left: 0;
    }
    .am-textarea-control {
      padding: 0;
    }
  }
  .review-teacher-remark, .review-teacher > .am-textarea-control > textarea {
    width: 100%;
    border: none;
    background: #F7FBFF;
    border-radius: 0.16rem;
    font-size: 0.373333rem;
    line-height: 1.2;
    color: #9496AB;
    text-align: left;
    padding: 0.213333rem 0.266667rem;
    min-height: 1.6rem;
    margin-top: 0.133333rem;
    //padding-bottom: 0.3rem;
    &:disabled {
      opacity: 1;
      -webkit-text-fill-color: #9496AB;
      color: #9496AB;
    }
    .remark-title {
      color: #FF6600;
    }
    .remark-auto {
      overflow: auto;
      max-height: 1.066667rem;
    }
  }
  .review-operate {
    padding: 0.3rem 0;
    .option-left, .option-right {
      font-size: 0.373333rem;
      color: #313451;
    }
    .btn-item {
      align-items: center;
      //padding-bottom: 0.3rem;
      span {
        margin-left: 0.133333rem;
        font-size: 0.373333rem;
        color: #04B0B9;
      }
    }
    .iconfont {
      font-size: 0.533333rem;
    }
    .remark {
      color: #02CBD5;
    }
    .share {
      color: #2480EF;
    }
  }
  &.footer-fixed-default {
    padding: 0;
  }
}

.homework-teacher-review-v2 {
  position: relative;
  padding: 1.066667rem 0.266667rem 0.533333rem;
  margin-top: 0.266667rem;
  background: #F7FBFF;
  border-radius: 0.106667rem;
  .review-remark {
    display: flex;
    align-items: flex-start;
  }
  .remark-left {
    width: 1.066667rem;
    font-size: 0.373333rem;
    color: #313451;
    text-align: left;
  }
  .remark-right {
    flex: 1;
    padding-left: 0.533333rem;
    font-size: 0.373333rem;
    color: #313451;
  }
}
.homework-review-btn {
  padding-top: 0.8rem;
}
.homework-review-submit, .homework-review-return {
  //width: 100%;
  font-size: 0.48rem;
  height: 1.066667rem;
  line-height: 1.066667rem;
  text-align: center;
  color: #fff;
  z-index: 20;
  border-radius: 2.666667rem;
  padding: 0 0.8rem;
  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.disabled {
    background: #B4B6C5;
  }
}
.homework-review-return {
  background: #F24F2D;
}
.homework-review-submit {
  background: #2D8DFF;
}

.btn-remark {
  color: #248AFF;
  border: 1px solid #248AFF;
  border-radius: 0.106667rem;
  font-size: 0.373333rem;
  margin: 0.533333rem 0.266667rem;
}

.homework-task-modal {
  .task-modal-header {
    padding: .266667rem 0;
    margin-bottom: .133333rem;
    text-align: center;
    background: #fff;
    p:first-child {
      font-size: .426667rem;
      color: #313451;
      text-align: center;
      line-height: .426667rem;
    }
  }
  .task-modal-content {
    background: #fff;
    p:first-child {
      height: .56rem;
      line-height: .56rem;
      font-size: .4rem;
      color: #313451;
      margin: 0 .266667rem;
      border-bottom: .026667rem solid rgb(222, 223, 229);
    }
  }
}

// 错题本
.library-problem {
  .app-scroll {
    top: 1.173333rem;
    bottom: 1.386667rem;
  }
}

.afterHomework__section {
  padding-bottom: 2.2rem;
  .title-tips {
    color: #9496AB;
    padding: 0.266667rem 0.533333rem;
    font-size: 0.373333rem;
  }
}

.homework-upload-option {
  padding-bottom: 0.666667rem;
  justify-content: flex-start;
  .option-item {
    width: 33%;
    color: #9496AB;
    text-align: center;
    .item-bac {
      position: relative;
      width: 2.0rem;
      height: 2.0rem;
      margin: 0 auto 0.213333rem;
      line-height: 2.0rem;
      background: #F7F8FA;
      border-radius: 0.4rem;
      & > input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        top: 0;
      }
      & > i {
        font-size: 0.64rem;
      }
    }
    .item-name {
      font-size: 0.373333rem;
    }
    .item-limit {
      font-size: 0.32rem;
      padding-top: 0.053333rem;
    }
  }
}

//退回订正
.homework-history {
  padding-bottom: 0.533333rem;
  .problem-show-wrap {
    padding: 0 0.266667rem;
  }
  .problem-show-header {
    padding: 0.32rem 0;
    color: #313451;
    text-align: left;
    border-bottom: .026667rem dashed rgb(222, 223, 229);
    h2 {
      font-size: 0.426667rem;
      line-height: 2;
    }
    span {
      font-size: 0.32rem;
      &:first-child {
        padding-right: 0.4rem;
      }
    }
  }
  .problem-show-body {
    position: relative;
    padding: 1.066667rem 0.266667rem 0.533333rem;
    background: #F9FAFB;
    margin-top: 0.266667rem;
  }
  .hop {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0.133333rem 0 0.133333rem 0;
    height: 0.8rem;
    width: 2.026667rem;
    line-height: 0.8rem;
    text-align: center;
    color: #fff;
    font-size: 0.373333rem;
    &.hop_student {
      background: #F58924;
    }
    &.hop_teacher {
      background: #2480EF;
    }
  }
  .problem-word {
    font-size: 0.4rem;
    color: #3B3120;
    letter-spacing: 0;
    text-align: justify;
    line-height: 0.693333rem;
  }
  .divider-line {
    height: 0.8rem;
    border-bottom: 1px dashed #BDBFCD
  }
}

// 新提交详情
.homework-submit_detail {
  width: 100%;
  background: #fff;
  overflow: hidden;
  padding: 0.213333rem 0.213333rem .533333rem;
}

.homework-submit-progress {
  margin-bottom: 0.64rem;
  .divider {
    padding: 0.426667rem 0.32rem;
    color: #9496AB;
  }
  .submit-list {
    width: 100%;
    overflow: hidden;
    margin-top: -0.133333rem;
    li {
      float: left;
      text-align: center;
      width: 25%;
      font-size: .373333rem;
      margin-top: 0.4rem;
      .submit-item-inner {
        position: relative;
        width: 90%;
        height: 0.853333rem;
        margin: 0 auto;
        background: #F0F1F6;
        border-radius: 0.48rem;
      }
      .gray {
        width: 90%;
        font-weight: 500;
        color: #313451;
        line-height: 0.853333rem;
        margin: 0 auto;
      }
      .corrected {
        position: absolute;
        right: 0;
        top: 0;
        color: #71c910;
      }
    }
  }
}

.homework-table {
  width: 88%;
  margin: 0.533333rem auto;
  .am-list-item {
    background-color: transparent;
    min-height: 0.4rem;
    .am-list-thumb:first-child {
      margin-right: 0;
    }
  }
  thead {
    tr {
      background: #FAFAFF;
    }
  }
  .table {
    width: 100%;
    background-color: #FFF;
    th {
      padding: 0.32rem 0;
      font-weight: 500;
      font-size: 0.346667rem;
    }
    td {
      padding: 0.266667rem 0;
      font-size: 0.32rem;
    }
  }
  .table th, .table td {
    border-bottom: 0.026667rem solid #EAEBF2;
    text-align: center;
    color: #303451;
  }
}
