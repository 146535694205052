
.btn {
  display: inline-block;
  padding: 0.16rem;
  margin-bottom: 0;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  border: 0;
}


.btn-primary {
  color: #fff;
  background-color: #2D8DFF;
  box-shadow: 0.053333rem 0.133333rem 0.106667rem 0 rgba(45, 141, 255, 0.22);
  border-radius: 0.106667rem;
}

.btn-primary[disabled] {
  background: #9496AB;
}

.btn-lg {
  padding: 0.266667rem 0.426667rem;
  font-size: 0.48rem;
  line-height: 1.3333333;
}

.btn-small {
  padding: 0.133333rem 0.266667rem;
}

.btn-disabled {
  background: #9496AB;
  border-radius: 4px;
}

.btn-solid-add, .btn-preview {
  border-radius: 2.666667rem;
  font-size: 0.32rem;
  padding: 0.106667rem 0.266667rem;
}

.btn-solid-add {
  border: 0.026667rem solid #2D8DFF;
  color: #2D8DFF;
}

.btn-clear {
  font-size: 0.32rem;
  color: #FFFFFF;
  padding: 0 0.32rem;
}

.btn-preview {
  border: 0.026667rem solid #F5A623;
  color: #F5A623;
}

.btn-warning {
  background: #F5A623;
  color: #fff;
  box-shadow: 0.053333rem 0.133333rem 0.106667rem 0 rgba(245, 166, 35, 0.22);
  border-radius: 0.106667rem;
}

.btn-warning[disabled] {
  opacity: 0.5;
}

.btn-delete {
  background: #9496AB;
  width: .533333rem;
  height: .533333rem;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 1.4;
  cursor: pointer;
}

.btn-delete i {
  color: #FFFFFF;
  font-size: 0.32rem;
}

.btn-score {
  display: inline-block;
  width: 36px;
  height: 36px;
  background-color: #FECF39;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 50%;
  font-size: 0.373333rem;
}

.btn-icon-edit {
  background-color: transparent;
  font-size: 0.533333rem;
  color: #F5A623;
}

.btn-icon-delete {
  background-color: transparent;
  font-size: 0.533333rem;
  color: #e5000e;
}

.btn-text-primary {
  color: $primary-color;
}

.btn-spin::before {
  display: inline-block;
  font-family: 'IconFont';
  color: #fff;
  content: '\e73c';
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.btn-update {
  border: 0.026667rem solid #2D8DFF;
  border-radius: 2.666667rem;
  color: #2D8DFF;
  font-size: 0.32rem;
  padding: 0 0.266667rem;
  line-height: 0.64rem;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.266667rem;
  }
  i {
    font-size: 0.32rem;
  }
}

.btn-edit-list {
  .icon-plus {
    font-size: 0.533333rem;
  }
  .icon-publish {
    font-size: 0.533333rem;
  }
  .btn-item {
    margin: 0 0.133333rem;
  }
}

.btn-list__main, .tag-list__main {
  background-color: #FFFFFF;
  max-height: 8.5rem;
  overflow: auto;
}

.btn-list__main {
  .list-adorn-group {
    padding: 0 0.533333rem;
  }
}


@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

// ant style
.am-button-primary {
  background-color: #2D8DFF;
  border-color: #2D8DFF;
  border-radius: 0.106667rem;
}
