.hdkt_app_analysis_placeholder {
  width: 100%;
  height: 0.533333rem;
  overflow: hidden;
}

.analysis {
  &-header {
    background-color: #EFEFF2;
    .header-title {
      color: #313451;
      font-size: 0.426667rem;
      font-weight: 600;
    }
  }
  &-select {
    .dorpdown-toggle {
      border: 0.026667rem solid #FFFFFF;
      border-radius: 1.333333rem;
      padding: 0 0.266667rem;
      line-height: 0.48rem;
      span {
        color: #F8E71C;
        font-size: 0.32rem;
      }
      .icon-dropdown {
        font-size: 0.32rem;
        color: #FFFFFF;
        &:before {
          content: '\e602';
        }
      }
    }
  }
  &-acct {
    .acct-wrap {
      height: 1.066667rem;
      box-shadow: 2px 2px 3px 0 rgba(148, 150, 171, 0.20);
    }
    .acct-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      background-color: #ffffff;
      padding: 0 0.16rem;
      li {
        padding: 0 0.16rem;
        line-height: 1.066667rem;
        span {
          position: relative;
          font-size: 0.32rem;
          color: #313451;
          display: block;
        }
        &.active {
          span {
            font-weight: 500;
          }
          span:after {
            content: '';
            position: absolute;
            height: 0.106667rem;
            width: 100%;
            bottom: 0;
            left: 0;
            background: #2D8DFF;
          }
        }
      }
    }
    .week-acct {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      overflow: hidden;
    }
    .day-acct {
      -ms-flex-negative: 1;
      flex-shrink: 1;
      margin-left: 0.106667rem;
    }
  }
  &-menu-wrap {
    width: 100%;
    background-color: #ffffff;
    margin-top: 0.266667rem;
  }
  &-single-menu {
    position: absolute;
    z-index: 190 !important;
    width: 100%;
    background-color: #ffffff;
  }
  &-menu-target {
    position: relative;
    border-bottom: 0.026667rem solid #F0F1F6;
    background-color: #ffffff;
    z-index: 190;
    li {
      float: left;
    }
    .col-4 {
      width: 25%;
    }
    .col-3 {
      width: 33.3333%;
    }
    .col-2 {
      width: 50%;
    }
    .col-1 {
      width: 100%;
    }
    .inner {
      text-align: center;
      line-height: 1.066667rem;
      span {
        display: inline-block;
        font-size: 0.32rem;
        color: #313451;
        vertical-align: middle;
        margin-right: 0.213333rem;
        max-width: 70%;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: keep-all;
        word-wrap: normal;
      }
      i {
        font-size: 0.266667rem;
        color: #9496AB;
      }
    }
  }
  &-menu-select {
    padding: 0.266667rem 0 0.533333rem 0;
    .title {
      font-size: 0.266667rem;
      color: #9496AB;
      padding: 0.133333rem 0 0 0.533333rem;
    }
    .analysis-scroll {
      max-height: 4.0rem;
      overflow-y: auto;
      overflow-scrolling: touch;
      -webkit-overflow-scrolling: touch;
    }
  }
  &-menu-list {
    li {
      padding: 0 0.533333rem;
      height: 0.746667rem;
      float: left;
      margin-top: 0.16rem;
    }
    .inner {
      position: relative;
      font-size: 0.32rem;
      color: #313451;
      padding: 0.16rem 0;
    }
    .checked {
      color: #F5A623;
      border-bottom: 0.026667rem solid #F5A623;
      &:after {
        position: absolute;
        top: 50%;
        right: 0.133333rem;
        z-index: 999;
        width: 0.133333rem;
        height: 0.213333rem;
        margin-top: -0.133333rem;
        border-style: solid;
        border-color: #F5A623;
        border-width: 0 0.026667rem 0.026667rem 0;
        content: ' ';
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  &-menu-footer {
    .btn {
      width: 100%;
      padding: 0.24rem 0;
      font-size: 0.426667rem;
    }
    .btn-cancel {
      color: #9496AB;
      border-top: 0.026667rem solid #f0f1f6;
    }
    .btn-confirm {
      background: #F5A623;
      border-top: 0.026667rem solid #F5A623;
    }
  }
  &-menu-mask {
    content: ' ';
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 169;
  }
}

.checked-arrow {
  position: relative;
  &:after {
    position: absolute;
    content: '';
    bottom: -0.16rem;
    left: 50%;
    margin-left: -0.133333rem;
    width: 0.266667rem;
    height: 0.266667rem;
    border-style: solid;
    border-color: #f0f1f6;
    border-width: 0.026667rem 0 0 0.026667rem;
    background-color: #ffffff;
    z-index: 10;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.analysis-charts {
  overflow: hidden;
  width: 100%;
  background-color: #ffffff;
  .chart-title {
    font-size: 0.32rem;
    color: #313451;
    text-align: center;
    padding: 0.373333rem 0 0.106667rem 0;
  }
}

.analysis-nav-list {
  li {
    float: left;
    width: 50%;
    border-bottom: 0.026667rem solid #f0f1f6;
    text-align: center;
    padding: 0.32rem 0;
    &.active {
      background: #F1F2F9;
    }
    p {
      font-size: 0.32rem;
      color: #313451;
      margin-top: 0.16rem;
    }
    i {
      width: 1.12rem;
      height: 1.12rem;
      display: block;
      margin: 0 auto;
    }
    .correct-sign {
      background-image: url("../images/analysis/correct-rate@2x.png");
      background-size: cover;
    }
    .submit-sign {
      background-image: url("../images/analysis/submit-rate@2x.png");
      background-size: cover;
    }
    .homework-sign {
      background-image: url("../images/analysis/homework@2x.png");
      background-size: cover;
    }
    .knowledge-correct-sign {
      background-image: url("../images/analysis/knowledge@2x.png");
      background-size: cover;
    }
    .error-problem-sign {
      background-image: url("../images/analysis/error-problem@2x.png");
      background-size: cover;
    }
    .class-report-sign {
      background-image: url("../images/analysis/class-report.png");
      background-size: cover;
    }
    .improve-score-sign {
      background-image: url("../images/analysis/exam-analysis.png");
      background-size: cover;
    }
    .homework-feedback-sign {
      background-image: url("../images/analysis/homework-feedback.png");
      background-size: cover;
    }
    .comprehensive-learning-sign {
      background-image: url("../images/analysis/learn-condition.png");
      background-size: cover;
    }
    &:nth-child(odd) {
      border-right: 0.026667rem solid #f0f1f6;
    }
    .border-none {
      border-bottom: 0;
    }
  }
  .analysis-close-tab {
    overflow: hidden;
    margin-top: -1.2rem;
    i {
      width: 1.1rem;
      height: 1.2rem;
      float: right;
      font-size: .75rem;
      color: #fff;
    }
  }
}

.analysis-homework-menu {
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0.053333rem 0.053333rem 0.08rem 0 rgba(148, 150, 171, 0.20);
  .select-label {
    font-size: 0.266667rem;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    min-width: 0.8rem;
  }
  .analysis-select {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    .dorpdown-toggle {
      padding: 0;
      border-color: #DFE1EC;
      span {
        color: #313451;
      }
      .icon-dropdown {
        color: #DFE1EC;
      }
    }
    .dropdown-menu {
      width: 2.666667rem;
      left: 50%;
      margin-left: -1.333333rem;
      top: 90%;
    }
  }
}

.analysis-select {
  .dorpdown-toggle {
    span {
      display: inline-block;
      max-width: 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: keep-all;
      word-wrap: normal;
    }
  }
}

.homework-analysis-group {
  width: 100%;
  background: #FFFFFF;
  margin-top: 0.266667rem;
  box-shadow: 0.053333rem 0.053333rem 0.133333rem 0 rgba(148, 150, 171, 0.22);
  padding: 0 0.4rem 0.266667rem 0.4rem;
  .analysis-title {
    padding: 0.266667rem 0;
  }
}

.analysis-title {
  position: relative;
  width: 2.666667rem;
  margin: 0 auto;
  text-align: center;
  &:before {
    content: '';
    display: block;
    width: 0.453333rem;
    height: 0.16rem;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.08rem;
    background-image: url("../images/analysis/title-left@2x.png");
    background-size: cover;
  }
  &:after {
    content: '';
    display: block;
    width: 0.453333rem;
    height: 0.16rem;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -0.08rem;
    background-image: url("../images/analysis/title-right@2x.png");
    background-size: cover;
  }
}

.analysis-table {
  thead {
    tr {
      background: #FAFAFF;
    }
  }
  .table {
    width: 100%;
    th {
      padding: 0.32rem 0;
      font-weight: 500;
      font-size: 0.346667rem;
    }
    td {
      padding: 0.266667rem 0;
      font-size: 0.32rem;
    }
  }
  .table th, .table td {
    border: 0.026667rem solid #EAEBF2;
    text-align: center;
    color: #303451;
  }
}

.homework-table-layer {
  width: 100%;
  th, td {
    padding: 0.16rem 0;
  }
  tr {
    cursor: pointer;
  }
  th {
    font-weight: 400;
    color: #9496AB;
  }
  .active {
    td {
      color: #ED9300;
    }
  }
}

.distribution-switch {
  margin-top: 0.266667rem;
  padding: 0 0.133333rem;
  overflow: hidden;
  li {
    width: 33.33%;
    text-align: center;
    padding: 0 0.133333rem;
    .nav-item {
      display: block;
      background: #FFFFFF;
      border-radius: 2.666667rem;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 0.32rem;
      color: #2D8DFF;
      border: 0.013333rem solid #2D8DFF;
    }
    &.active .nav-item {
      background-image: linear-gradient(-90deg, #51D3EC 0%, #2D8DFF 100%);
      color: #ffffff;
      border: 0;
    }
  }
}

.correct-rate-group {
  padding: 0.4rem;
  background-color: #ffffff;
}

.rankings-details {
  .item {
    line-height: 0.586667rem;
  }
  .scope-key {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.32rem;
    color: #313451;
    min-width: 1.466667rem;
  }
  .scope-value {
    font-size: 0.346667rem;
    color: #313451;
    font-weight: 500;
    vertical-align: middle;
  }
}

.homework-index-site {
  padding: 0.266667rem;
}

.rankings-details {
  margin-top: 0.533333rem;
  padding: 0 0 0.8rem 0.533333rem;
}

.analysis-knowledge-total {
  .knowledge-summary-item {
    overflow: hidden;
    ul {
      margin-top: 0.4rem;
    }
    li {
      float: left;
      width: 1.733333rem;
      text-align: center;
      position: relative;
      span {
        font-size: 0.346667rem;
        color: #ED9300;
        font-weight: 500;
        &.few {
          color: #1B82FB;
        }
      }
      p {
        font-size: 0.32rem;
        color: #303451;
        margin-top: 0.16rem;
      }
      &.decorate:after {
        position: absolute;
        content: '';
        height: 0.48rem;
        background-color: #E4E5EB;
        width: 0.026667rem;
        right: 0;
        top: 50%;
        margin-top: -0.24rem;
      }
    }
  }
  .current-knowledge {
    font-size: 0.32rem;
    color: #313451;
    margin-top: 0.4rem;
    .knowledge {
      font-size: 13px;
      color: #7ED321;
    }
  }
}

.table-scroll {
  padding-bottom: 0.533333rem;
  overflow-x: scroll;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
}

.knowledge-correct-rate {
  padding-bottom: 0;
  .analysis-table {
    width: 18.666667rem;
  }
}

.current-knowledge {
  background-color: #ffffff;
  height: 1.066667rem;
  padding: 0 0.266667rem;
  & > div {
    height: 100%;
  }
  .btn-knowledge {
    background: #F0F1F6;
    border: 0;
    border-radius: 0.106667rem;
    font-size: 0.32rem;
    color: #313451;
    padding: 0.053333rem 0.16rem;
  }
}

.analysis-problem-result {
  overflow: hidden;
  li {
    min-width: 2.133333rem;
    text-align: left;
    float: left;
    p {
      margin-top: 0.16rem;
    }
  }
  .answer, .rate {
    font-size: 0.48rem;
    font-weight: 500;
  }
  .rate {
    color: #7ED321;
  }
  .answer {
    color: #ED9300;
  }
}

.right-answer-group {
  margin-top: 0.533333rem;
  .right-answer {
    margin-top: 0.213333rem;
  }
}

.subjective-right-answer, .analysis-problem-result {
  .title-bracket {
    margin: 0.533333rem 0 0.266667rem -0.16rem;
  }
}

.subjective-right-answer {
  .title-bracket {
    margin-top: 0;
  }
}

.analysis-job-list {
  padding: .346667rem .266667rem;
  li {
    margin-bottom: .266667rem;
    padding: .233333rem .533333rem;
    background: #fff;
    color: #313451;
    font-size: .373333rem;
    border-radius: .163333rem;
    box-shadow: 0.06rem 0.06rem 0.06rem #ddd;
    position: relative;
    .analysis-job-issued {
      font-size: .32rem;
    }
    p {
      line-height: .6rem;
    }
    .analysis-dot1 {
      display: inline-block;
      width: .16rem;
      height: .16rem;
      border-radius: 50%;
      position: absolute;
      right: .4rem;
      bottom: .1rem;
      background: -webkit-linear-gradient(180deg, #FDE777, #FBCB42); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(180deg, #FDE777, #FBCB42); /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(180deg, #FDE777, #FBCB42); /* Firefox 3.6 - 15 */
      background: linear-gradient(180deg, #FDE777, #FBCB42); /* 标准的语法 */
    }
    .analysis-dot2 {
      display: inline-block;
      width: .266667rem;
      height: .266667rem;
      border-radius: 50%;
      position: absolute;
      right: 0;
      bottom: 0;
      background: -webkit-linear-gradient(180deg, #59C1FF, #2D8DFF); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(180deg, #59C1FF, #2D8DFF); /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(180deg, #59C1FF, #2D8DFF); /* Firefox 3.6 - 15 */
      background: linear-gradient(180deg, #59C1FF, #2D8DFF); /* 标准的语法 */
    }
  }
}

.analysis-menu-target {
  .select-name {
    font-size: .373333rem;
    color: #313451;
    line-height: 1.066667rem;
    text-align: left;
  }
  .select-data {
    float: right;
  }
  .select-icon {
    .icon-dropdown {
      vertical-align: baseline;
    }
    .icon-dropdown:before {
      content: "\E602";
      font-size: .266667rem;
      color: #9496AB;
    }
    li {
      span {
        font-size: .4rem;
      }
    }
    span {
      font-size: .32rem;
    }
  }
}

.analysis-menu-target.student {
  border: none;
  background: #f0f1f6
}

.class-report-active-subject {
  color: #2D8DFF;
}

.class-report-hd {
  margin-top: .7rem;
  margin-bottom: .566667rem;
  h1, h2 {
    padding-left: .4rem;
    padding-right: .4rem;
    font-size: .426667rem;
    display: inline-block;
    line-height: .8rem;
    text-align: center;
    color: #fff;
    background: #F5A623;
    box-shadow: 3px 3px 3px #F5A623;
    margin: 0;
  }
}

.class-report-commom-bar {
  height: 1.066667rem;
  background: -moz-linear-gradient(left, #1D86FF 0%, #0A66D1 100%);
  background: -webkit-gradient(linear, left, right, color-stop(0%, #1D86FF), color-stop(100%, #0A66D1));
  background: -webkit-linear-gradient(left, #1D86FF 0%, #0A66D1 100%);
  background: -o-linear-gradient(left, #1D86FF 0%, #0A66D1 100%);
  background: -ms-linear-gradient(left, #1D86FF 0%, #0A66D1 100%);
  background: linear-gradient(to right, #1D86FF 0%, #0A66D1 100%);
}

.class-report-btn-primary {
  border-radius: .32rem;
  width: 1.866667rem;
  height: .64rem;
  line-height: .64rem;
  color: #fff;
  text-align: center;
  background: transparent;
  border: 1px solid #fff;
  font-size: .373333rem;
  &.active {
    color: #2B8BFD;
    background: #fff;
  }
}

.class-report-total-title {
  height: 1.066667rem;
  line-height: 1.066667rem;
  background: #E7F3FF;
  text-align: center;
  font-size: .426667rem;
  color: #313451;
}

.class-report-knowledge-title {
  text-align: center;
  margin-top: .373333rem;
  margin-bottom: .373333rem;
  font-size: .426667rem;
}

.class-report-questions-btn {
  margin-top: .4rem;
  overflow: hidden;
  .answer-analysis {
    color: #1B82FB;
    border: 1px solid #1B82FB;
    line-height: .8rem;
    text-align: center;
    font-size: .373333rem;
    border-radius: .4rem;
    float: right;
    margin-right: .533333rem;
    padding-left: .533333rem;
    padding-right: .53333rem;
  }
  .knowledge-point {
    color: #ed9300;
    border: 1px solid #ED9300;
  }
}

.class-report-problem-common-parse {
  border: 1px solid #2D8DFF;
  margin-top: .3rem;
  margin-bottom: .3rem;
  padding: .266667rem;
  box-shadow: 0.15rem -0.15rem 0rem #e6f9ff;
  .exam-options {
    font-size: .373333rem;
  }
}

.class-report-problem-common-analysis {
  margin-top: .3rem;
  border-top: 1px solid #E9ECF1;
  font-size: .32rem;
  padding-top: .373333rem;
  padding-bottom: .373333rem;
  line-height: .5rem;
}

.class-report-problem-common-sourse {
  color: #9496AB;
}

.class-report-problem-common-problemContent {
  margin-bottom: .3rem;
  font-size: .373333rem;
}

.class-report-problem-common-problemAnswer {
  font-size: .373333rem;
}

//课程概况
.class-report-hd.summary-title {
  margin-bottom: -.6rem;
  h1, h2 {
    position: relative;
    box-shadow: 3px 3px 3px #7ed321;
    background: #7ED321;
  }
}

.class-report-general-content {
  background: #FFFCF0;
  color: #9B8428;
  font-size: .373333rem;
  padding: .133333rem;
  p {
    margin-bottom: .266667rem;
  }
}

.class-report-course-content {
  border: 1px dashed #E0CE85;
  padding: .933333rem .266667rem .5rem;
}

.class-report-body-wrap {
  overflow: hidden;
  background: #fff;
  padding: .133333rem .266667rem;
}

//全部题目单
.class-report-problem-summary {
  padding: .186667rem .293333rem;
  background: #F6FDFF;
  font-size: .373333rem;
  .class-report-problem-summary-num {
    color: #2D8DFF;
    line-height: .713333rem;
    padding-bottom: .133333rem;
  }
  .class-report-expand {
    margin: 0;
  }
}

.class-report-arrow-title {
  margin-top: .2rem;
  margin-bottom: .2rem;
  color: #313451;
  font-size: .373333rem;
  i {
    display: inline-block;
    width: .4rem;
    height: .26rem;
    margin-right: .1rem;
  }
}

.custom-tooltip {
  background: rgba(255, 255, 255, 0.8);
  padding: .266667rem;
  .desc {
    color: #313451;
  }
  .desc-1 {
    color: #1B82FB;
    margin-top: .266667rem;
  }
  .desc-2 {
    color: #7ED321;
    margin-top: .266667rem;
  }
}

.date-select-title {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.date-select-title-close {
  position: absolute;
  right: .4rem;
}
