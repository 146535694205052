.header-root {
  height: 1.173333rem;
}

.header-double-root {
  height: 2.346667rem;
}

.header-triple-root {
  height: 3.519999rem
}

.header-quadra-root {
  height: 4.693332rem
}

.header-wrapper {
  position: relative;
  width: 100%;
  height: 1.173333rem;
  left: 0;
  top: 0;
  background: transparent;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 101;
  &.fixed {
    position: fixed;
  }
  .brand {
    font-size: 0.48rem;
    color: #FFFFFF;
    padding-left: .4rem;
  }
  &.z-auto {
    z-index: auto;
  }
}

.header-placeholder {
  position: relative;
  width: 100%;
  height: 1.173333rem;
}

.header-primary {
  background: #2D8DFF;
  color: #fff;
  .header-back i {
    color: #fff;
  }
}

.mix-header {
  width: 100%;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
  }
}

.header-back {
  width: 1.173333rem;
  height: 100%;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  i {
    color: #DDD;
    font-size: 0.533333rem;
  }
}

.hd-navbar-right {
  padding-right: 0.4rem;
  font-size: 0.32rem;
  .icon-filter {
    font-size: 0.533333rem;
  }
}

.header-title {
  font-size: 0.48rem;
  line-height: 1.173333rem;
}
.header-title__left {
  font-size: 0.373333rem;
  line-height: 1.173333rem;
}

.header-title {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  max-width: 70%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  word-wrap: normal;
  margin: 0;
}

.footer-wrap {
  width: 100%;
  height: 1.386667rem;
  height: 13.86667vw;
}

.tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 0.053333rem;
  background: #FFFFFF;
  box-shadow: inset 0 0.013333rem 0 0 #D3D7DE;
  z-index: 100;
}

.tabbar-item {
  position: relative;
  width: 25%;
  text-align: center;
  a {
    display: block;
    font-size: 0.266667rem;
    color: #9496AB;
    padding-bottom: 0.026667rem;
    &.active {
      color: #2D8DFF;
    }
  }
  .tabbar-img {
    height: 0.96rem;
    line-height: 0.96rem;
    img {
    	width: 1.12rem;
    }
  }
  .msg-tip {
    position: absolute;
    top: 0.213333rem;
    right: 0.533333rem;
    width: 8px;
    height: 8px;
    background: #FF001F;
    border-radius: 50%;
  }
  //i {
  //  max-height: 0.96rem;
  //  line-height: 1.066667rem;
  //  text-align: center;
  //  display: block;
  //  font-size: 0.8rem;
  //}
}

.maskBar-nav {
  text-align: center;
  .maskBar-navList {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.maskBar-navList {
  .nav-item {
    position: relative;
    line-height: 1.2rem;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    span {
      position: relative;
      color: #313451;
      font-size: 0.4rem;
      display: block;
    }
    &.active span {
      //font-size: 0.48rem;
      color: #2480EF;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.053333rem;
        background: #2480EF;
      }
    }
    .msg-tip {
      position: absolute;
      top: 0.4rem;
      right: 0.16rem;
      width: 5px;
      height: 5px;
      background: #FF001F;
      border-radius: 50%;
    }
  }
  .btn-demo {
    position: absolute;
    top: 50%;
    margin-top: -9px;
    left: 2.133333rem;
    background: #fe5300;
    color: #fff;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    width: 70px;
    text-align: center;
    border-radius: 10px;
  }
}
