html {
  color: #000;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  touch-action: manipulation;
  text-size-adjust: none;
}

html, body {
  height: 100%;
  width: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, hr, button, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  margin: 0;
  padding: 0
}

input, select, textarea {
  font-size: 100%
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

fieldset, img, button {
  border: 0
}

button {
  background: transparent;
}

abbr, acronym {
  border: 0;
  font-variant: normal
}

del {
  text-decoration: line-through
}

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: 500
}

ol, ul {
  list-style: none
}

caption, th {
  text-align: left
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: 400
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -.5em
}

sub {
  bottom: -.25em
}

img, input, select {
  vertical-align: middle;
}

img {
  max-width: 100%;
}

ins, a, a:hover {
  text-decoration: none
}

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a, button, div[type=button] {
  cursor: pointer;
}

.bg-default {
  background: #fff;
}

/* 清理浮动 */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: "\20 ";
  clear: both;
  height: 0;
}

.clearfix {
  zoom: 1; /* for IE6 IE7 */
}

/* 禁止折行/允许折行 */
.nowrap {
  white-space: nowrap;
  word-break: keep-all;
}

.wrap {
  white-space: normal;
  word-break: break-all;
}

/* 溢出隐藏，IE6下需要外容器设置宽度 */
.ellipsis {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  word-wrap: normal;
}

/* 隐藏, 通常用来与 JS 配合 */
body .hide {
  display: none;
}

body .hidden {
  visibility: hidden;
}

input:focus {
  outline: 0 !important
}

a:active, a:focus, button:active, button:focus, span:active, span:focus {
  outline: 0
}

input::-ms-clear {
  display: none
}

.top-center {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  -webkit-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
}

.flex, .ui-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.flex {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.flex-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-around {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.flex-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.grow-full {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
}

.flex-start-center {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
}

.flex-ui-center {
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
}

.flex-shrink {
  flex-shrink: 0;
}

.flex-truncate {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ui-center {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1100;
  overflow: hidden;
}

.ui-center {
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.ui-center-block {
  text-align: center;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.placeholder {
  position: relative;
  width: 100%;
  bottom: 0;
  background: transparent;
  height: 1.333333rem;
}

.pop-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: .3;
  filter: alpha(opacity=30);
  z-index: 666
}

.pop-overlay.transparent {
  opacity: 0;
  filter: alpha(opacity=0);
}

.pop-fixed {
  position: fixed
}

.back-top {
  display: none;
  position: fixed;
  right: 0.25rem;
  bottom: 0.5rem;
  width: 0.9rem;
  height: 0.9rem;
  background-image: url("../../images/back-top@2x.png");
  background-size: cover;
  cursor: pointer;
  z-index: 999;
  -ms-transform: translate(1.5rem, 0);
  -webkit-transform: translate3d(1.5rem, 0, 0);
  transform: translate3d(1.5rem, 0, 0);
  -webkit-transition: all .25s cubic-bezier(.215, .61, .355, 1);
  -moz-transition: all .25s cubic-bezier(.215, .61, .355, 1);
  transition: all .25s cubic-bezier(.215, .61, .355, 1);
}

.back-top.active {
  display: block;
  -ms-transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.overflow-scroll {
  overflow: auto;
  overflow-scrolling: touch;
}

button, html [type="button"] {
  -webkit-appearance: none !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.m-t-10 {
  margin-top: 0.266667rem;
}

.m-b-10 {
  margin-bottom: 0.266667rem;
}

.m-b-5 {
  margin-bottom: .133333rem;
}

.m-b-20 {
  margin-bottom: .533333rem;
}

.m-t-20 {
  margin-top: .533333rem;
}

.m-r-15 {
  margin-right: .4rem;
}

.m-l-15 {
  margin-left: .4rem;
}

.p-l-10 {
  padding-right: 0.32rem;
}

.p-10 {
  padding: 0.266667rem 0;
}

.backg-gray {
  background: #f0f1f6 !important;
}

.width20 {
  width: 20% !important;
}

.width30 {
  width: 30% !important;
}

.width25 {
  width: 25% !important;
}

.width40 {
  width: 50% !important;
}

.width50 {
  width: 50% !important;
}

.width60 {
  width: 60% !important;
}

.width80 {
  width: 80% !important;
}

.disblock {
  display: block;
}

.font14 {
  font-size: .373333rem;
}

.font20 {
  font-size: .533333rem;
}

.font16 {
  font-size: .426667rem;
}

.lineHei20 {
  line-height: .533333rem;
}

.colorYe {
  color: #F5A623
}

.colorGre {
  color: #7ED321;
}

.colorBlue {
  color: #1B82FB;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.textIndent5 {
  text-indent: .133333rem;
}

.paddingLeft5 {
  padding-left: 5px;
}
