@import "../../variables";
@import "../../mixins/motion";

@mixin move-motion($className, $keyframeName) {
  @include make-motion($className, $keyframeName);
  .#{$className}-enter, .#{$className}-appear {
    opacity: 0;
    animation-timing-function: $ease-out-quint;
  }
  .#{$className}-leave {
    animation-timing-function: $ease-in-quint;
  }
}

@include move-motion('move-up', 'moveUp');
@include move-motion('move-down', 'moveDown');
@include move-motion('move-left', 'moveLeft');
@include move-motion('move-right', 'moveRight');

@keyframes moveDownIn {
  0% {
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveDownOut {
  0% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes moveLeftIn {
  0% {
    transform-origin: 0 0;
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes moveLeftOut {
  0% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes moveRightIn {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0%);
  }
}

@keyframes moveRightOut {
  0% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes moveUpIn {
  0% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveUpOut {
  0% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
}
