@import "./core/utils";
.online-exam{
  .card-header {
    @include ellipsis();
    font-size: 16px;
    color: #313451;
    padding: 12px 20px;
    background: #F9FCF5;
  }
  .card-container {
    padding: 0 10px;
  }
  .card-body {
    font-size: 12px;
    color: #9496AB;
    padding: 8px 10px;
    border-bottom: 1px dashed #E9ECF1;
    .online-row {
      display: flex;
      flex-wrap: wrap;
    }
    .online-col {
      line-height: 1.8;
      &.col-r-18 {
        margin-right: 18px;
      }
      &.online-col-50 {
        width: 50%;
        @include ellipsis();
      }
    }
  }
  &-list {
    padding: 10px;
    .exam-wrap {
      margin-bottom: 10px;
    }
    .card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      .btn {
        border-radius: 100px;
        padding: 0.16rem 12px;
      }
    }
  }
  &-entry {
    padding: 10px 0;
    .card-footer {
      padding: 20px;
      .title-default {
        font-size: 14px;
      }
      .btn-wrap {
        width: 100%;
        text-align: center;
        .btn {
          padding: 10px 35px;
          font-size: 14px;
          border-radius: 100px;
        }
        .btn-default {
          color: #fff;
          background-color: #9496AB;
          box-shadow: 0.053333rem 0.133333rem 0.106667rem 0 rgba(148, 150, 171, 0.22);
        }
      }
    }
    .time-box {
      width: 100%;
      text-align: center;
      padding: 25px 0;
    }
  }
  &-paper {
    .paper-name {
      height: 1.173333rem;
      line-height: 1.173333rem;
      background: #FFF;
      text-align: center;
      border-bottom: 1px dashed #E9ECF1;
      font-size: 14px;
      color: #313451;
      padding: 0 10px;
      &.fixed {
         position: fixed;
         top: 1.173333rem;
         left: 0;
         right: 0;
         width: 100%;
         z-index: 666;
       }
    }
    .homework-switch {
      &.fixed {
        position: fixed;
        top: 2.346666rem;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 666;
      }
    }
    .left-time {
      height: 32px;
      line-height: 32px;
      background: #F9FCF5;
      text-align: center;
      .time-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.266667rem;
        color: #69CC00;
        font-size: 14px;
        i {
          display: block;
          width: 0.533333rem;
          height: 0.533333rem;
          margin-right: 5px;
        }
        .img-left {
          background-image: url('../images/homework/left-time@2x.png');
          background-size: cover;
        }
        .LeftTime__box {
          .date-time, .date-name {
            font-size: 14px;
            color: #69CC00;
          }
        }
      }
    }
    .homework-adorn {
      height: 2px;
    }
  }
}
