
.form-control {
  display: block;
  width: 100%;
  height: 1.013333rem;
  padding-left: 0.266667rem;
  font-size: 0.373333rem;
  color: #303451;
  line-height: 1.42857143;
  background-color: #fff;
  border: 0.016rem solid #D6D8E5;
  border-radius: 0.106667rem;
  &.primary {
    border: 0.016rem solid #2D8DFF;
    color: #2D8DFF;
  }
  &.small {
    height: 0.72rem;
  }
}

.form-area {
  display: block;
  width: 100%;
  padding: 0.16rem 0 0.16rem 0.266667rem;
  font-size: 0.373333rem;
  color: #303451;
  background-color: #fff;
  resize: none;
  border: 0.016rem solid #D6D8E5;
  border-radius: 0.106667rem;
}

.form-checkbox {
  display: block;
  .checkbox {
    display: block;
    width: 0.426667rem;
    height: 0.426667rem;
    line-height: 0.4rem;
    border: 0.026667rem solid #9496AB;
    border-radius: 50%;
    text-align: center;
    background-color: #FFFFFF;
    font-size: 0.106667rem;
    color: #FFFFFF;
    &.checked {
      background: #2D8DFF;
      border-color: #2D8DFF;
    }
  }
}

.form-group {
  width: 100%;
  margin-bottom: 0.266667rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.form-adorn-group {
  padding-top: 0.373333rem;
  .txt {
    font-size: 0.4rem;
    color: #313451;
  }
  .form-control {
    color: #9496AB;
    border: none;
    border-radius: 0;
    border-bottom: 0.026667rem solid #F0F1F6;
    padding: 0;
  }
}

.form-placeholder-group {
  border-bottom: 0.026667rem solid #F0F1F6;
  .list-placeholder {
    position: relative;
    color: #9496AB;
    margin-top: -0.266667rem;
    z-index: 1;
    font-size: 0.32rem;
    padding-bottom: 0.32rem;
  }
  .list-adorn-group {
    border-bottom: 0;
  }
}

.form-evaluate-group {
  padding: 0 0.266667rem;
  .form-control, .list-item-form {
    border-radius: 0;
    border: 0;
    border-bottom: .026667rem solid #f0f1f6;
    &:focus, &.am-input-focus {
      border-bottom-color: #2D8DFF;
    }
  }
  .Limit_wrap {
    .limit-text {
      margin-top: 0;
    }
  }
  .comments {
    display: flex;
    flex-wrap: wrap;
    li {
      background: #B4B6C5;
      border-radius: 100px;
      color: #FFF;
      margin: 0 0.16rem 0.186667rem 0;
      text-align: center;
      padding: 0.026667rem 0.266667rem;
    }
  }
}


.hd-checkbox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hd-form-checkbox, .hd-form-radio {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  font-size: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.hd-form-checkbox {
  border: 1px solid #ccc;
  border-radius: 50%;
  &:after {
    position: absolute;
    display: none;
    top: 1px;
    right: 4px;
    z-index: 999;
    width: 5px;
    height: 9px;
    border-style: solid;
    border-width: 0 1px 1px 0;
    content: ' ';
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #FFFFFF;
  }
}

.hd-form-radio {
  &:after {
    position: absolute;
    display: none;
    top: 0;
    right: 5px;
    z-index: 999;
    width: 7px;
    height: 12px;
    border-style: solid;
    border-width: 0 1.5px 1.5px 0;
    content: ' ';
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #108ee9;
  }
}

.hd-checked .hd-form-checkbox {
  border-color: #108ee9;
  background: #108ee9;
  &:after {
    display: block;
  }
}

.hd-checked .hd-form-radio {
  &:after {
    display: block;
  }
}


.list__main {
  .hd-checked .hd-form-radio {
    &:after {
      display: none;
    }
  }
  .hd-checked {
    &.list-adorn-group {
      background: #F1F7FF;
    }
  }
}

.hd-radio-input, .hd-checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  font-size: 0;
  border: 0 none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


/*select*/
.menu-select-modal {
  position: absolute;
  width: 100%;
  padding-top: 1.173333rem;
  bottom: 0;
  height: 100%;
  overflow: hidden;
}

.menu-select-container {
  height: 100%;
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  .flex-item {
    font-size: 0.4rem;
    color: #313451;
  }
}

.menu-select-left {
  height: 100%;
  margin-left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  box-shadow: 0.053333rem 0 0.106667rem 0 #EEEEEE;
  background-color: #FFFFFF;
}

.menu-select-right {
  max-height: 100%;
  overflow-y: scroll;
  background-color: #FFFFFF;
}

.menu-list-left {
  li {
    padding: 0.533333rem 0.266667rem 0.533333rem 0.533333rem;
    &.active {
      position: relative;
      background: #F0F1F6;
      i {
        position: absolute;
        right: 0.266667rem;
        top: auto;
      }
    }
  }
}

.menu-list-right {
  li {
    padding: 0.32rem 0.266667rem 0.32rem 0.533333rem;
    &.active {
      color: #2D8DFF;
    }
  }
}


.list-item-form {
  border: 0.016rem solid #D6D8E5;
  border-radius: 0.106667rem;
  min-height: 1.013333rem;
  &.am-input-item {
    height: 1.013333rem;
    padding-left: 0.266667rem;
  }
  .am-input-control, .am-input-control input, .am-input-control .fake-input-container .fake-input {
    font-size: 0.373333rem;
    color: #303451;
  }
}