// Variables
// --------------------------

$IconFontPath: "../font" !default;
$IconFontVersion: "1.0.0" !default;

$icon-delete: "\e60c";
$icon-empty-work: "\e607";
$icon-stu: "\e611";
$icon-back: "\e60b";
$icon-task: "\e62a";
$icon-subject: "\e648";
$icon-link: "\e629";
$icon-file: "\e62c";
$icon-vertical-more: "\e613";
$icon-plus: "\e636";
$icon-offline: "\e60f";
$icon-publish: "\e61e";
$icon-close: "\e6bb";
$icon-right-arrow: "\e686";
$icon-left-arrow: "\e915";
$icon-bottom-arrow: "\e602";
$icon-dropdown: "\e625";
$icon-circle-plus: "\e8df";
$icon-circle-offline: "\e8e6";
$icon-filter: "\e61f";
$icon-down-move: "\e65d";
$icon-up-move: "\e8e7";
$icon-timer: "\e851";
$icon-see: "\e914";
$icon-download: "\e605";
$icon-comment: "\e704";
$icon-delete: "\e60c";
$icon-danger: "\e643";
$icon-unreviewed: "\e640";
$icon-unsubmit: "\e640";
$icon-uncomplete: "\e641";
$icon-return: "\e630";
$icon-success: "\e644";
$icon-warning: "\e640";
$icon-menu: "\e64b";
$icon-bofang: "\e614";
$icon-circle-edit: "\e668";
$icon-circle-delete: "\e615";
$icon-collect: "\e66e";

$icon-folder: "\e671";
$icon-empty-default: "\e607";


$icon-file: "\e62f";
$icon-word: "\e626";
$icon-img: "\e62d";
$icon-radio: "\e628";
$icon-video: "\e62e";
$icon-ppt: "\e624";

//tree
$icon-caret-down: "\e631";
$icon-people: '\e67f';
$icon-department: '\e681';
$icon-call: '\e685';
$icon-search: '\e638';
$icon-group: '\e6da'
