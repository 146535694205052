// 分割线
.divider {
  display: table;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  font-weight: 500;
  color: #313451;
  font-size: 0.373333rem;
  margin: 0 auto;
  padding: 0.426667rem 0;
  overflow: hidden;
  &:before,  &:after {
  	content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
  }
  &-inner-text {
    display: inline-block;
    padding: 0 0.64rem
  }
}

.divider-vertical {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  color: rgba(0,0,0,0.09);;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  background: #e8e8e8;

  position: relative;
  display: inline-block;
  width: 1px;
  top: -0.06em;
  height: 0.6em;
  margin: 0 8px;
  vertical-align: middle;
}


// 左边条状
.title-strip {
  position: relative;
  font-size: 0.373333rem;
  color: #313451;
  padding-left: 0.266667rem;
  white-space: nowrap;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.106667rem;
    background: #02CBD5;
  }
}

// 标题下线
.title-bottom-line {
  font-size: 0.4rem;
  color: #313451;
  padding-left: 0.533333rem;
  overflow: hidden;
  &-inner {
    display: block;
  	padding: 0.373333rem 0;
  	border-bottom: 0.026667rem solid #e8e8e8;
  }
}

.title-default {
  color: #313451;
  font-size: 0.373333rem;
  margin: 0;
}

.title-danger {
  color: #F24F2D;
  font-size: 0.373333rem;
  margin: 0;
}

.title-primary, .exam-analytic {
  font-size: 0.373333rem;
  color: #2D8DFF;
  margin: 0;
}
