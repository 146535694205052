@mixin clearfix() {
  zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }
}

@mixin nowrap() {
  white-space: nowrap;
  word-break: keep-all;
}

@mixin wrap() {
  white-space: normal;
  word-break: break-all;
}

/* 溢出隐藏，IE6下需要外容器设置宽度 */
@mixin ellipsis() {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  word-wrap: normal;
}
