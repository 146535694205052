@import "../../variables";
@import "../../mixins/motion";

@mixin fade-motion($className, $keyframeName) {
  @include make-motion($className, $keyframeName);
  .#{$className}-enter, .#{$className}-appear {
    opacity: 0;
    animation-timing-function: $ease-out-quint;
  }
  .#{$className}-leave {
    animation-timing-function: $ease-in-quint;
  }
}

@include fade-motion('fade', 'fade');

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
