.description {
  text-align: left;
  margin-bottom: 0.133333rem;
}

.confirm-section {
  max-height: 10.666667rem;
  overflow-y: auto;
}

.class-wrap {
  justify-content: flex-start;
  flex-wrap: wrap;
  li {
    margin-right: 0.133333rem;
    margin-bottom: 0.266667rem;
    border: 0.026667rem solid #2D8DFF;
    padding: 0.026667rem 0.133333rem;
    border-radius: 0.8rem;
    color: #2D8DFF;
    height: 0.56rem;
    text-align: center;
    line-height: 0.506666rem;
    font-size: 0.373333rem;
  }
}
