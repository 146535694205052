// Mixins
// --------------------------

@mixin icon($icon) {
  @include icon-font();
  content: $icon;
}

@mixin icon-font() {
  font-family: IconFont;
  position: relative;
  top: 0.026667rem;
  display: inline-block;
  font-weight: 400;
  font-style: normal;
  font-size: 0.426667rem;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
