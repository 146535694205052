.class-report {
  //.header-wrapper {
  //  z-index: 0;
  //}
  .class-report-select {
    position: relative;
    background-color: #fff;
  }
  .class-report-schedule {
    float: left;
    width: 47%;
    -webkit-border-radius: .08rem;
    -moz-border-radius: .08rem;
    border-radius: .08rem;
    margin: 1.5%;
    padding: .313333rem .2rem .24rem;
    color: #fff;
    line-height: .4rem;
  }
  .class-report-schedule-h2 {
    line-height: .6rem;
    margin-bottom: .1rem;
    font-size: .376667rem;
  }
}

.class-report-subtag {
  max-width: 3rem;
  line-height: .48rem;
  display: inline-block;
  font-size: .303333rem;
  padding: 0 .233333rem;
  border-radius: .233333rem;
  border: 1px solid #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: .2rem;
  overflow: hidden;
}

.class-report-select-tab {
  color: #313451;
  font-size: .373333rem;
  margin-left: .15667rem;
  font-weight: bold;
  line-height: .8rem;
  span {
    display: inline-block;
    font-weight: bolder;
    color: #2989FA;
    font-size: .5rem;
    margin-right: .2rem;
  }
}

.class-report-schedule-wrap {
  overflow: hidden;
  background: #fff;
  padding: .133333rem;
}

.class-report-schedule-wrap.student {
  background: #f0f1f6;
  padding: 0;
}

.class-report-schedule {
  float: left;
  width: 47%;
  -webkit-border-radius: .08rem;
  -moz-border-radius: .08rem;
  border-radius: .08rem;
  margin: 1.5%;
  padding: .313333rem .2rem;
  color: #fff;
  line-height: .4rem;
}

.class-report-schedule-h2 {
  line-height: .6rem;
  margin-bottom: .1rem;
  font-size: .376667rem;
  .class-report-subtag {
    height: .48rem;
    line-height: .48rem;
    display: inline-block;
    font-size: .303333rem;
    padding: 0 .233333rem;
    border-radius: .233333rem;
    border: 1px solid #fff;
  }
}

.class-bg0 {
  background: #FF4669;
  &.active {
    box-shadow: 2px 4px 7px #FF4669;
  }
}

.class-bg1 {
  background: #FFA949;
  &.active {
    box-shadow: 2px 4px 7px #ffa949;
  }
}

.class-bg2 {
  background: #B771FF;
  &.active {
    box-shadow: 2px 4px 7px #B771FF;
  }
}

.class-bg3 {
  background: #00D1A5;
  &.active {
    box-shadow: 2px 4px 7px #00D1A5;
  }
}

.class-bg4 {
  background: #5ECB32;
  &.active {
    box-shadow: 2px 4px 7px #5ECB32;
  }
}

.class-bg5 {
  background: #898CFF;
  &.active {
    box-shadow: 2px 4px 7px #898CFF;
  }
}

.class-report-wrap {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
}

.class-report-schedule-info {
  color: #313451;
  line-height: .626667rem;
  font-size: .373333rem;
}

.class-report-vote-chart {
  background: #F4FFFC;
  .recharts-wrapper {
    margin-top: .3rem;
    padding-top: .2rem;
  }
  .recharts-legend-item-text {
    font-size: .4rem;
    font-weight: bold;
  }
}

.class-report-vote-chart-name {
  text-align: center;
  font-size: .373333rem;
}

.class-report-body {
  padding: .266667rem
}

.class-report-body {
  .class-report-course-general {
    position: relative;
    margin-bottom: .906667rem;
  }
}

/**
教师端表扬情况
 */
.class-report-praise-table {
  width: 100%;
  th, td {
    border: 1px solid #EAEBF2;
    border-collapse: collapse;
    text-align: center;
    font-size: .363333rem;
    color: #313451;
    padding: .263333rem .10667rem;
  }
  th {
    background: #78B6FF;
    color: #fff;
    min-width: 1.3rem;
  }
}

/**
学生端表扬情况
 */
.class-report-praise-detailsName {
  font-size: .373333rem;
  color: #313145;
  margin-bottom: .3rem;
}

.class-report-praise-nums {
  background: #F6FDFF;
  padding-bottom: .4rem;
  p {
    line-height: 1.6rem;
    text-align: center;
    font-size: .533333rem;
    i {
      font-size: .64rem;
      color: #9496AB;
    }
  }
  span {
    font-size: .373333rem;
  }
}

.class-report-knowledge {
  .recharts-legend-item {
    margin-left: .6rem !important;
    margin-right: .3rem !important;
  }
}

.class-report-knowledge-count {
  font-size: .373333rem;
}

.class-report-knowledge-chart {
  .recharts-legend-item-text {
    font-size: .373333rem;
  }
  .recharts-surface {
    vertical-align: text-bottom !important;
  }
}

.class-report-knowledge-chart-name {
  text-align: center;
  font-size: .426667rem;
  color: #313451;
  line-height: 1.066667rem;
}

.recharts-text.recharts-cartesian-axis-tick-value {
  tspan {
    width: 1rem;
  }
}

.class-report-testing-content {
  padding: .213333rem;
  background: #EEF6FF;
  font-size: .373333rem;
  line-height: .48rem;
  margin-bottom: .133333rem
}

.class-report-testing-name {
  color: #313451;
}

.class-report-testing-type {
  margin-top: .2rem;
  margin-bottom: .2rem;
  color: #2D8DFF;
  span {
    margin-right: .44rem;
  }
}

.class-report-testing-colon {
  font-weight: bold;
  margin-right: 0.1rem !important;
}

.class-report-testing-composite {
  overflow: hidden;
  span {
    color: #2D8DFF;
  }
  button {
    line-height: .693333rem;
    width: 2.4rem;
    border-radius: .1rem;
    background: #2D8DFF;
    color: #fff;
    float: right;
    margin-bottom: 5px;
    margin-right: 5px;
    box-shadow: 3px 3px 3px #63B8FF;
  }
}

.class-report-screen-carousel {
  height: 2.853333rem;
  ul {
    overflow-y: hidden;
    overflow-x: auto;
    height: 100%;
    white-space: nowrap;
    li {
      margin-right: .133333rem;
      display: inline-block;
      img {
        height: 2.853333rem;
        width: 4.4rem;
      }
    }
  }
}

.class-report-offline-detail {
  z-index: 1100;
}

.class-report-offline-detail-body {
  padding: .213333rem;
}

.class-report-offline-detail-tab {
  line-height: .8rem;
  font-size: .373333rem;
  color: #333653;
  border-radius: .4rem;
  overflow: hidden;
  margin: .206667rem .08rem .533333rem;
  p {
    float: left;
    background: #EAEBF1;
    text-align: center;
    width: 50%;
    &.active {
      background: #7ED321;
      color: #fff;
    }
  }
}

.class-report-offline-detail-studentList {
  &.active {
    display: block;
  }
  display: none;
  table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    th {
      background: #78B6FF;
      color: #fff;
    }
    th, td {
      line-height: .848rem;
      border: 1px solid #EAEBF2;
      width: 33%;
      text-align: center;
      font-size: .373333rem;
    }
    td {
      background: #fff;
    }
  }
}

.class-report-result-summary {
  background: #EEF6FF;
  font-size: .406667rem;
  padding: .3rem;
  line-height: .48rem;
  span {
    font-weight: 500;
    display: inline-block;
    margin-top: .45rem;
  }
}

.class-report-result-number {
  color: #2D8DFF;
}

.class-report-common-btn {
  display: block;
  line-height: .8rem;
  color: #fff;
  text-align: center;
  background: #2D8DFF;
  font-size: .32rem;
  margin: 10px auto;
  border-radius: 0.53rem;
  padding-left: .5rem;
  padding-right: .5rem;
  box-shadow: .026667rem .026667rem .133333rem #2e8dff;
  button {
    color: #fff
  }
}

.class-report-questions-body {
  background: #F0F1F6;
}

.class-report-questions-operate {
  padding: 0 .4rem;
  overflow: hidden;
  line-height: 1.173333rem;
  color: #9496AB;
  font-size: .373333rem;
  margin-bottom: .266667rem;
  background: #fff;
  i {
    font-size: .373333rem;
    vertical-align: text-bottom;
  }
  p {
    visibility: hidden;
    float: left;
    width: 20%;
    text-align: center;
    &.active {
      visibility: visible
    }
  }
  div {
    text-align: center;
    float: left;
    width: 60%;
  }
}

.class-report-questions-lists {
  display: none;
  background: #fff;
  &.active {
    display: block;
  }
}

.class-report-typeName {
  margin-bottom: .366667rem;
  color: #313451;
  font-size: .426667rem;
}

.class-report-questions-active {
  font-size: .533333rem;
  color: #2D8DFF;
}

.class-report-difficulty {
  position: absolute;
  left: .3rem;
  top: -.2rem;
  span {
    display: inline-block;
    width: 2.4rem;
    line-height: .8rem;
    background: #FFE7E6;
    text-align: center;
    border-radius: .053333rem;
    font-size: .373333rem;
    color: #D0021B;
  }
}

.class-report-questions-cell {
  padding: .266667rem .4rem;
  .option-item {
    min-width: 24%;
    float: left;
    margin-right: 1%;
  }
  &.inActive {
    display: none;
  }
}

.class-report-questions-parse {
  position: relative;
  padding: .93rem .48rem .533333rem;
  background: #F6FDFF;
  .exam-options {
    font-size: .373333rem;
  }
  .exam-title {
    font-size: .373333rem;
    color: #313451;
  }

}

.class-report-rightAnswer i {
  color: #F5A623;
}
.class-report-rightAnswer .right-false{
  display: inline-block;
  float: right;
  height: 0.4rem;
  line-height: 0.05rem;
  color: #F5A623;
  font-size: .533333rem;
}
.class-report-rightCorrect .correct-rate {
  color: #7ED321;
  font-size: .533333rem;
}

.class-report-studentAnswer i {
  color: #1B82FB;
}

.class-report-teacher-correct {
  .class-report-rightAnswer, .class-report-rightCorrect {
    margin-top: .26rem;
    font-size: .373333rem;
    font-weight: bold;
    float: left;
    text-align: center;
    span {
      display: inline-block;
      margin-right: .213333rem;
    }
    i {
      vertical-align: middle;
      font-size: .533333rem;
      font-style: normal;
    }
  }
}

.class-report-student-correct {
  overflow: hidden;
  div {
    margin-top: .26rem;
    font-size: .373333rem;
    font-weight: bold;
    float: left;
    text-align: center;
    p {
      color: #313145;
      font-size: .373333rem;
      font-weight: bold;
      text-align: left;
    }
    i {
      width: 100%;
      text-align: left;
      display: inline-block;
      margin-bottom: .25rem;
      vertical-align: middle;
      font-size: .533333rem;
      font-style: normal;
    }
  }
  .class-report-rightAnswer {
    width: 32%;
  }
  .class-report-rightCorrect {
    width: 32%;
  }
  .class-report-studentAnswer {
    width: 36%;
    padding-left: .7rem;
    border-left: 1px solid #F2F2F5;
  }
}

.class-report-answer-lists {
  padding: .266667rem;
  margin-bottom: .226667rem;
  p {
    font-size: .426667rem;
    color: #F5A623;
  }
  div {
    font-size: .373333rem;
    color: #313451;
    line-height: .78rem;
    margin-top: .1rem;
  }
}

.class-report-select-wrap {
  position: relative;
  background: #fff;
  .select-list {
    li {
      line-height: .6rem;
    }
  }
  .class-report-select-classname {
    position: relative;
    margin-left: 1rem;
    line-height: 1.12067rem;
    text-align: center;
    font-size: .32rem;
    color: #313451;
    i {
      margin-left: .13rem;
      font-size: .266667rem;
      color: #9496ab;
    }
  }
  .dorpdown-toggle {
    line-height: 1.0667rem;
    padding: 0;
    color: #313451;
    font-size: .293333rem;
  }
}

.class-report-cover {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 666;
  display: none;
  &.active {
    display: block;
  }
}

.class-report-select-lesson {
  font-size: .373333rem;
  line-height: 1.06667rem !important;
  padding-left: .35rem;
  font-weight: bold;
  span {
    border-left: 2px solid #2989FA;
    margin-right: .16rem;
  }
}

.class-report-student-sel-subject {
  width: 100%;
  overflow: auto;
  //height: 1.066667rem;
  background: #fff;
  box-shadow: .053333rem .053333rem .08rem rgba(148, 150, 171, 0.20);
  ul {
    white-space: nowrap;
    li {
      display: inline-block;
      float: none;
      font-size: .373333rem;
      color: #313145;
      padding-left: .373333rem;
      padding-right: .373333rem;
      line-height: 1.06667rem;
      &.class-report-active-subject {
        color: #2D8DFF;
        border-bottom: 2px solid #2D8DFF;
      }
    }
  }
}

.class-report-student-sel-time {
  background: #f0f1f6;
  padding-top: .2rem;
  .inner {
    text-align: right;
    margin-right: .35rem;
    line-height: 1.066667rem;
  }
}

/**
课堂知识点正确率 学生端
 */
.class-report-common-table {
  width: 100%;
  th, td {
    border: 1px solid #EAEBF1;
    border-collapse: collapse;
    text-align: center;
    font-size: .363333rem;
    color: #313451;
    padding: .283333rem .10667rem;
  }
  th {
    background: #f9fafe;
    color: #313451;
    font-size: 16px;
    min-width: 1.3rem;
    line-height: .6rem;
    min-height: 1rem;
    font-weight: 600
  }
}

.class-report-knowledge-detailsName {
  font-size: .373333rem;
  color: #313451
}

/**
*学生端答题结果
*/
.class-report-student-result-summary {
  background: #F3FFE7;
  padding: .266667rem .373333rem;
  font-size: .373333rem;
  .class-report-result-number {
    line-height: .783333rem;
  }
  .class-report-result-level {
    line-height: .733333rem;
    span {
      font-size: .533333rem;
      margin-left: .3rem;
      margin-right: .5rem;
      vertical-align: bottom;
      &.class-report-result-studentCorrectRate {
        color: #F5A623;
      }
      &.class-report-result-correctRate {
        color: #7ED321;
      }
    }
  }
}

/**
*学生端课堂报告
*/
.class-report-expand {
  margin-top: .266667rem;
  margin-bottom: .266667rem;
  background: #F6FDFF;
  height: 1.066667rem;
  line-height: 1.066667rem;
  text-align: center;
  color: #2D8DFF;
  span {
    color: #2D8DFF;
    margin-left: .166667rem;
    font-size: .373333rem;
  }
}

.class-report-result-typeName {
  color: #313451;
  font-size: .426667rem;
  line-height: .833333rem;
  font-weight: bold;
}

.class-report-result-parse {
  margin-top: .3rem;
  background: #f3ffe7;
  font-size: .373333rem;
  padding: .56667rem .393333rem;
}

.class-report-result-list {
  margin-top: .266667rem;
  background: #F6FDFF;
  position: relative;
  color: #313451;
  font-size: .373333rem;
  padding: .80667rem .593333rem 1.3rem;
}

.class-report-analysis-btn {
  button {
    color: #1B82FB;
    text-align: center;
    float: right;
    border: 1px solid #1B82FB;
    border-radius: .4rem;
    width: 2.666667rem;
    line-height: .8rem;
  }
}

.class-report-problemContent {
  line-height: .8rem;
}

.class-report-result-answer {
  .result-answer-div {
    margin-right: .3rem;
    margin-top: .2rem;
    margin-bottom: .3rem;
  }
  .result-answer-span {
    font-size: .373333rem;
    font-weight: bold;
  }

  .class-report-result-correctRate {
    p {
      color: #7ED321;
    }
  }
}

.class-report-similar-exercises-brief {
  font-size: .373333rem;
  padding-bottom: .2rem;
}

.class-report-similar-exercises-num {
  font-size: .373333rem;
  padding: .48rem 0;
  line-height: .7rem;
}

.class-report-similar-exercises-line {
  border-bottom: 1px dashed #7ed321;
  margin-left: -0.2666rem;
  margin-right: -0.2667rem;
  margin-bottom: .48rem;
}
