.case-item {
  padding: 0.266667rem 0.533333rem;
  border-bottom: 0.026667rem solid rgba(148, 150, 171, 0.3);
  &:last-child {
    border-bottom: 0;
  }
}

.filter-problem__wrap {
  width: 100%;
  background: #FFFFFF;
  .filter-item {
    width: 33.3333333%;
    float: left;
  }
}

.chapter-result {
  width: 100%;
  background-color: #f0f1f6;
  padding: 0.16rem 0.266667rem;
  .icon-close {
    color: #9496AB;
    font-size: 0.48rem;
  }
}

.exams-wrap {
  width: 100%;
  overflow: hidden;
  background: #FFFFFF;
  padding: 0 0.533333rem;
  &.extra {
    border-top: 0.026667rem solid rgba(148, 150, 171, .3);
  }
  .question-group {
    padding: 0.4rem 0 0.266667rem 0;
    border-bottom: 0.026667rem solid rgba(148, 150, 171, .3);
    &:last-child {
      border-bottom-color: transparent;
    }
  }
}

.exam-header {
  font-size: 0.373333rem;
  color: #313451;
  .exam-title {
    overflow: auto;
    line-height: 0.586667rem;
    p:first-child {
      display: inline-block;
    }
  }
  .title-index {
    padding-right: 0.133333rem;
  }
  table {
    width: 100% !important;
    table-layout: auto !important;
  }
}

.exam-options {
  margin-top: 0.133333rem;
  .option-item {
    padding: 0.133333rem 0;
    line-height: 0.533333rem;
  }
}

.exam-footer {
  margin-top: 0.133333rem;
}

.exam-category {
  font-size: 0.32rem;
  color: #9496AB;
  margin-right: 0.8rem;
  p {
    line-height: 0.586667rem;
  }
  &:last-child {
    margin-right: 0;
  }
}

.exam-btn-wrap {
  align-self: flex-end;
  .btn-add, .btn-sort {
    height: 0.8rem;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.32rem;
    color: #313451;
    i {
      font-size: 0.533333rem;
      color: #2D8DFF;
    }
    .btn-text {
      margin-left: 0.053333rem;
      line-height: 1;
    }
  }
  .btn-sort {
    margin-right: 0.133333rem;
  }
  button[disabled] {
    i {
      color: #9496AB;
    }
  }
}

.exam-form-group {
  padding: 0.266667rem 0.533333rem;
  background-color: #FFFFFF;
  .exam-form-title {
    width: 6.133333rem;
    .form-control {
      height: 0.853333rem;
      padding: 0.106667rem 0.266667rem;
    }
  }
  .total-score {
    label {
      font-size: 0.373333rem;
      color: #9496AB;
      margin-right: 0.24rem;
    }
  }
}

.exam-form-score {
  width: 1.333333rem;
  .score-item {
    min-height: 0.72rem;
    &.am-input-item {
      height: 0.72rem;
      padding: 0;
      border: 1px solid #2D8DFF;
      border-radius: 2.666667rem;
      text-align: center;
    }
    .am-list-line {
      padding: 0;
    }
    .am-input-control, .am-input-control input {
      color: #2D8DFF;
      font-size: 0.32rem;
      text-align: center;
    }
  }
}

.exam-operate-score {
  .form-title {
    flex: 1;
    text-align: right;
    font-size: 0.373333rem;
    color: #9496AB;
  }
}

.paper-types {
  height: 1.333333rem;
  padding: 0 0.533333rem;
  background-color: #f0f1f6;
  h1 {
    font-size: 0.373333rem;
    color: #313451;
  }
}

.file-type {
  flex-shrink: 1;
  min-width: 0.906667rem;
  span {
    display: block;
    text-align: center;
    width: 0.906667rem;
    height: 1.066667rem;
    background: url("../images/filetype/empty@2x.png");
    background-size: 100% 100%;
  }
  .paper {
    background: url("../images/filetype/paper@2x.png");
    background-size: 100% 100%;
  }
  .doc, .docx, .rtf, .wps {
    background: url("../images/filetype/word@2x.png");
    background-size: 100% 100%;
  }
  .xls, .xlsx, .et {
    background: url("../images/filetype/excel@2x.png");
    background-size: 100% 100%;
  }
  .bmp, .tiff, .gif, .jpeg, .jpg, .exif, .psd, .png, .svg, .cdr, .eps {
    background: url("../images/filetype/img@2x.png");
    background-size: 100% 100%;
  }
  .flash, .swf {
    background: url("../images/filetype/flash@2x.png");
    background-size: 100% 100%;
  }
  .ppt, .pptx, .dps {
    background: url("../images/filetype/ppt@2x.png");
    background-size: 100% 100%;
  }
  .zip {
    background: url("../images/filetype/zip@2x.png");
    background-size: 100% 100%;
  }
  .rar {
    background: url("../images/filetype/rar@2x.png");
    background-size: 100% 100%;
  }
  .mp4, .avi, .rm, .rmvb, .wmv, .mov, .mpeg, .dat, .vob, .flv, .mkv {
    background: url("../images/filetype/video@2x.png");
    background-size: 100% 100%;
  }
  .txt {
    background: url("../images/filetype/txt@2x.png");
    background-size: 100% 100%;
  }
  .exe {
    background: url("../images/filetype/exe@2x.png");
    background-size: 100% 100%;
  }
  .cda, .wav, .mp3, .m4a, .wma, .ra, .midi, .ogg, .ape, .flac, .aac {
    background: url("../images/filetype/audio@2x.png");
    background-size: 100% 100%;
  }
  .pdf {
    background: url("../images/filetype/pdf@2x.png");
    background-size: 100% 100%;
  }
}

.paper-problem {
  width: 100%;
  background-color: #FFFFFF;
}

.paper-preview-section {
  padding-bottom: 2.133333rem;
}

.course-sign i {
  font-size: 0.8rem;
  color: #29A0FA;
}

.course-wrap {
  .course-item {
    position: relative;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: .32rem 0;
    border-bottom: .026667rem solid #f0f1f6;
  }
  .course-file {
    word-break: break-all;
    word-wrap: normal;
    padding-left: 0.373333rem;
    .title {
      font-size: 0.4rem;
      color: #313451;
      display: block;
    }
    p {
      font-size: 0.32rem;
      color: #9496AB;
      margin-top: 0.133333rem;
      white-space: normal;
      word-break: break-all;
    }
  }
  .course-delete {
    i {
      color: #F86753;
    }
  }
}

.paper-basket {
  position: fixed;
  width: 1.173333rem;
  height: 1.173333rem;
  right: 0.533333rem;
  bottom: 0.533333rem;
  z-index: 1200;
  background: #2D8DFF;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.32rem;
  border-radius: 50%;
  box-shadow: 0 0.053333rem 0.32rem 0 rgba(45, 141, 255, 0.40);
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.paper-case-list {
  background: #FFFFFF;
  width: 100%;
  .case-item {
    position: relative;
    padding: 0.4rem 0.533333rem;
    .self-paper {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-width: 0.666667rem;
      border-style: solid;
      border-color: #FECF39 #FECF39 transparent transparent;
      i {
        position: absolute;
        top: -0.346667rem;
        right: -0.426667rem;
        font-size: 0.32rem;
        color: #fff;
      }
    }
  }
  .file-group {
    font-size: 0.266667rem;
    color: #9496AB;
    margin-top: 0.16rem;
    //span {
    //  margin-right: 0.4rem;
    //  &:last-child {
    //    margin-right: 0;
    //  }
    //}
  }
}

.paper-header {
  background-color: #FFFFFF;
  padding: 0.4rem 0.533333rem;
}

.paper-intro {
  background-color: #FFFFFF;
  padding: 0 0.533333rem 0.266667rem 0.533333rem;
  span {
    font-size: 0.32rem;
    color: #9496AB;
    margin-right: 0.533333rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.answer-content-words {
  background: #F0F1F6;
  border: 0.026667rem solid #CCCDD6;
  border-radius: 0.106667rem;
  padding: 0.133333rem;
  min-height: 1.706667rem;
  font-size: 0.373333rem;
  color: #9496AB;
}

.subject-horizontal {
  background: #FFFFFF;
  box-shadow: 0 0.053333rem 0.106667rem 0 rgba(0, 0, 0, 0.10);
  padding: 0.24rem;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  overflow-x: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  span {
    display: block;
    min-width: 1.333333rem;
    vertical-align: middle;
    padding: 0.16rem 0;
    border: 0.026667rem solid #2D8DFF;
    border-radius: 2.666667rem;
    text-align: center;
    color: #2D8DFF;
    margin-right: 0.533333rem;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    word-wrap: normal;
    &:last-child {
      margin-right: 0;
    }
    &.checked {
      background: #2D8DFF;
      color: #FFFFFF;
    }
  }
  .item {
    min-width: unset;
    overflow: unset;
    padding: 0.16rem;
  }
}

.t-subject-horizontal {
  font-size: .373333rem;
  background: #FFFFFF;
  box-shadow: 0 0.053333rem 0.106667rem 0 rgba(0, 0, 0, 0.10);
  padding: 0.24rem;
  display: flex;
  justify-content: flex-start;
  min-width: 100%;
  width: fit-content;
  //overflow-x: auto;
  //overflow-scrolling: touch;
  //-webkit-overflow-scrolling: touch;
  .item {
    position: relative;
    vertical-align: middle;
    margin-right: 0.533333rem;
    border: .053333rem solid #ffffff;
    border-radius: 30px;
    background: #FFFFFF;
    span {
      display: block;
      padding: 0.16rem .4rem;
      border: .026667rem solid #DADCED;
      text-align: center;
      color: #313451;
      overflow: hidden;
      border-radius: 30px;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: keep-all;
      word-wrap: normal;
    }
    &:last-child {
      margin-right: 0;
    }
    &.checked {
      border-color: #DEEDFF;
      background: #7DB8FF;
      span {
        color: #FFF;
        border-color: #7DB8FF;
      }
    }
  }
}

.problem-type__place {
  min-height: 1.12rem;
}

.exam-knowledge {
  line-height: 0.533333rem;
}

.analyticbox-body {
  overflow: auto;
  line-height: 0.533333rem;
  margin-top: 0.106667rem;
}

.exam-answer {
  margin-bottom: 0.266667rem;
}

.score-wrap {
  p {
    margin-top: 0.133333rem;
  }
}

.btn-score.teacher {
  background-color: #f6a623;
}

.paper-preview-header {
  background: #FFFFFF;
  padding: 0.533333rem 0.133333rem;
  text-align: center;
  .paper-title {
    font-size: 0.426667rem;
    color: #303451;
  }
  .paper-details {
    margin-top: 0.266667rem;
    .item {
      margin: 0 0.266667rem;
    }
  }
  span.item {
    font-size: 0.32rem;
    color: #303451;
  }
}

//文件夹
.Folder_subject {
  display: flex;
  height: 1.066667rem;
  align-items: center;
  background: #FFFFFF;
  font-size: 0.373333rem;
  color: #313451;
  padding: 0 0.533333rem;
  margin: 0.026667rem 0 0.266667rem 0;
}

.exam-btn-add-group {
  text-align: right;
  padding: 0 0.533333rem 0.266667rem;
}
