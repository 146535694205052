@mixin effect() {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, .25, 1);
}

.hd-popup, .hd-popdown, .hd-popleft {
  position: fixed;
  width: 100%;
  left: 0;
  background-color: transparent;
  &-close {
    display: none;
  }
  &-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow-scrolling: touch;
    outline: 0;
  }
  &-fade-enter, &-fade-appear {
    opacity: 0;
    @include effect();
    animation-play-state: paused;
  }

  &-fade-leave {
    @include effect();
    animation-play-state: paused;
  }

  &-fade-enter, &-fade-enter-active, &-fade-appear, &-fade-appear-active {
    animation-name: popupFadeIn;
    animation-play-state: running;
  }

  &-fade-leave, &-fade-leave-active {
    animation-name: popupFadeOut;
    animation-play-state: running;
  }

  &-slide-fade-enter,
  &-slide-fade-appear {
    transform: translate(0, 100%);
  }

  &-slide-fade-enter,
  &-slide-fade-appear,
  &-slide-fade-leave {
    @include effect();
    animation-play-state: paused;
  }
}

.hd-popup, .hd-popleft {
  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #333333;
    background-color: rgba(51, 51, 51, 0.3);
    height: 100%;
    filter: alpha(opacity=30);
    z-index: 1050;
    &-hidden {
      display: none;
    }
  }
}

.hd-popup {
  bottom: 0;
  background-color: #fff;
  &-header {
    // display: flex;
    // align-items: center;
    background-image: -webkit-linear-gradient(top, #e7e7e7, #e7e7e7, transparent, transparent);
    background-image: linear-gradient(to bottom, #e7e7e7, #e7e7e7, transparent, transparent);
    background-position: bottom;
    background-size: 100% 1px;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: row;
    &-left, &-right {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-item {
    color: #0ae;
    font-size: 18px;
    height: 44px;
    line-height: 44px;
    cursor: pointer;
    box-sizing: border-box;
    // flex: 1;
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    &-active {
      background-color: #ddd;
    }
  }
  &-title {
    flex: 1;
    color: #666;
    cursor: default;
  }

  &-slide-fade-enter, &-slide-fade-enter-active, &-slide-fade-appear, &-slide-fade-appear-active {
    animation-name: popupFadeInUp;
    animation-play-state: running;
  }

  &-slide-fade-leave, &-slide-fade-leave-active {
    animation-name: popupFadeInUpOut;
    animation-play-state: running;
  }
}

.hd-popdown {
  top: 0;
  &-slide-fade-enter, &-slide-fade-enter-active, &-slide-fade-appear, &-slide-fade-appear-active {
    animation-name: popupFadeInDown;
    animation-play-state: running;
  }

  &-slide-fade-leave, &-slide-fade-leave-active {
    animation-name: popupFadeInDownOut;
    animation-play-state: running;
  }
}

.hd-popleft {
  top: 0;
  bottom: 0;
  width: 75%;
  &-slide-fade-enter, &-slide-fade-enter-active, &-slide-fade-appear, &-slide-fade-appear-active {
    animation-name: popupFadeInLeft;
    animation-play-state: running;
  }
  &-slide-fade-leave, &-slide-fade-leave-active {
    animation-name: popupFadeInLeftOut;
    animation-play-state: running;
  }
  &-content {
    height: 100%;
    overflow: scroll;
    background-color: #ffffff;
  }
}

.adapt-modal {
  max-height: 60%;
  width: 80%;
  position: relative;
  z-index: 1060;
  border-radius: .16rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.adapt-header {
  position: fixed;
  width: 80%;
  height: 1.226667rem;
  line-height: 1.2267rem;
  background-image: url("../images/confirm_bg@2x.png");
  background-size: cover;
  padding: 0 .32rem;
  font-size: .48rem;
  color: #fff;
}

.adapt-section {
  margin-top: 1.22667rem;
  background: #fff;
  overflow: auto;
  padding: .24rem .266667rem .4rem;
}
