@import "../../variables";
@import "../../mixins/motion";

@mixin zoom-motion($className, $keyframeName, $duration:$animation-duration-base) {
  @include make-motion($className, $keyframeName, $duration);
  .#{$className}-enter, .#{$className}-appear {
    opacity: 0;
    animation-timing-function: $ease-out-quint;
  }
  .#{$className}-leave {
    animation-timing-function: $ease-in-quint;
  }
}

// For Modal, Select choosen item
@include zoom-motion('zoom', 'zoom');
// For Popover, Popconfirm, Dropdown
@include zoom-motion('zoom-big', 'zoomBig');
// For Tooltip
@include zoom-motion('zoom-big-fast', 'zoomBig', $animation-duration-fast);

@include zoom-motion('zoom-up', 'zoomUp');
@include zoom-motion('zoom-down', 'zoomDown');
@include zoom-motion('zoom-left', 'zoomLeft');
@include zoom-motion('zoom-right', 'zoomRight');


@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.2);
  }
}

@keyframes zoomBigIn {
  0% {
    opacity: 0;
    transform: scale(.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes zoomUpIn {
  0% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(.8);
  }
  100% {
    transform-origin: 50% 0%;
    transform: scale(1);
  }
}

@keyframes zoomUpOut {
  0% {
    transform-origin: 50% 0%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(.8);
  }
}

@keyframes zoomLeftIn {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(.8);
  }
  100% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
}

@keyframes zoomLeftOut {
  0% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(.8);
  }
}

@keyframes zoomRightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(.8);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
}

@keyframes zoomRightOut {
  0% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(.8);
  }
}

@keyframes zoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(.8);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}

@keyframes zoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(.8);
  }
}
