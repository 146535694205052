.rate-btn {
  position: fixed;
  width: 1.6rem;
  height: 1.6rem;
  right: 0.533333rem;
  bottom: 2.133333rem;
  z-index: 1000;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.32rem;
  border-radius: 50%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.rate-img {
  position: absolute;
  left: 0;
  top: 0;
}

.rate-wrap {
  padding: 0.32rem 0.266667rem;
}
.rate-container {
  width: 100%;
  background: #FFFFFF;
  border-radius: 0.533333rem 0.533333rem 0.106667rem 0.106667rem;
  padding-bottom: 0.8rem;
  .header {
    background: #F7FBFF;
    border-radius: 0.533333rem 0.533333rem 0.106667rem 0.106667rem;
    padding: 0.48rem 0;
    text-align: center;
    & > i {
      font-size: 0.48rem;
      color: #FE5300;
    }
    .title {
      font-size: 0.48rem;
      color: #273130;
      padding-left: 0.133333rem;
    }
  }
  .comment {
    padding: 0.533333rem 0.346667rem;
    text-align: center;
    .textarea {
      width: 100%;
      min-height: 3.2rem;
      background: #F7FBFF;
      border-radius: 0.053333rem;
      border: none;
      padding: 0.32rem;
      &::placeholder {
        font-size: 0.373333rem;
        color: #9496AB;
      }
    }
    .main-rate {
      justify-content: center;
      padding: 0.16rem 0 0.213333rem;
      .rc-rate {
        padding-left: 1.12rem;
      }
    }
    .score {
      min-width: 0.96rem;
      font-size: 0.32rem;
      color: #9496AB;
      padding-top: 0.16rem;
    }
  }
  .rate {
    padding: 0 0.48rem 1.333333rem;
    text-align: center;
    .item {
      padding: 0.16rem 0;
      &-title {
        font-size: 0.373333rem;
        color: #273130;
        padding-top: 0.133333rem;
        text-align: left;
      }
      &-score {
        min-width: 0.96rem;
        font-size: 0.32rem;
        color: #9496AB;
        padding-top: 0.16rem;
        text-align: left;
      }
      &-describe {
        font-size: 0.32rem;
        color: #9496AB;
        line-height: 1.5;
        text-align: left;
      }
    }
  }
  .submit {
    padding: 0 0.48rem;
    .am-button-primary {
      background: #2D8DFF;
    }
  }
  .category {
    background: #2D8DFF;
    border-radius: 0.4rem;
    font-size: 0.426667rem;
    color: #FFF;
    padding: 0.133333rem 0.346667rem;
  }
}

