@import "../../styles/variables";

$spin-prefix-cls: "spinner";
$spin-circle-size: 40;

.#{$spin-prefix-cls} {
  vertical-align: middle;
  text-align: center;
  opacity: 0;
  position: absolute;
  display: none;
  padding: 20px 0;
  &-spinning {
    opacity: 1;
    position: static;
    display: block;
  }
  &-container {
    position: relative;
    zoom: 1;
  }
  &-nested-loading {
    & > div > .#{$spin-prefix-cls} {
      display: block;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 100;
      padding: 0;
      .#{$spin-prefix-cls}-rect {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -0.533333rem 0 0 -0.666667rem;
      }
      .#{$spin-prefix-cls}-circle {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -10px 0 0 -10px;
      }
      .#{$spin-prefix-cls}-text {
        position: fixed;
        top: 50%;
        width: 100%;
        text-shadow: 0 1px 2px #fff;
      }
    }
  }

  &-blur {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: .7;
    filter: blur(.5px);
    -webkit-transform: translateZ(0);
  }
  &-text {
    text-align: center;
    color: $primary-color;
    font-size: 14px;
    padding: 10px 0;
  }
  &-rect {
    margin: 0 auto;
    width: 1.333333rem;
    height: 1.066667rem;
    text-align: center;
    font-size: 0.266667rem;
    overflow: hidden;
    & > i {
      background-color: $primary-color;
      height: 100%;
      width: 0.16rem;
      display: inline-block;
      margin: 0 0.08rem 0 0;
      animation: stretchDelay 1.2s infinite ease-in-out;
      &:nth-child(2) {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
      &:nth-child(3) {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
      }
      &:nth-child(4) {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
      &:nth-child(5) {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
    }
  }
  &-circle {
    margin: 0 auto;
    width: 20px;
    height: 20px;
    position: relative;
    .container1 > div, .container2 > div, .container3 > div {
      width: 6px;
      height: 6px;
      background-color: $primary-color;
      border-radius: 100%;
      position: absolute;
      animation: bounceDelay 1.2s infinite ease-in-out;
      animation-fill-mode: both;
    }
    .circle-container {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .circle1 {
      top: 0;
      left: 0;
    }

    .circle2 {
      top: 0;
      right: 0;
    }

    .circle3 {
      right: 0;
      bottom: 0;
    }

    .circle4 {
      left: 0;
      bottom: 0;
    }
    .container1 {
      .circle2 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
      .circle3 {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
      }
      .circle4 {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
      }
    }
    .container2 {
      -webkit-transform: rotateZ(45deg);
      transform: rotateZ(45deg);
      .circle1 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
      .circle2 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
      .circle3 {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }
      .circle4 {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
      }
    }

    .container3 {
      -webkit-transform: rotateZ(90deg);
      transform: rotateZ(90deg);
      .circle1 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
      }
      .circle2 {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
      }
      .circle3 {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
      }
      .circle4 {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
      }
    }
  }
  &-wrap {
    padding: 20px;
    overflow: hidden;
  }
}

.loading-placeholder {
  min-height: 300px;
}

.loading-wrap {
  overflow: hidden;
  padding: 0.533333rem 0;
}

.am-list {
  .#{$spin-prefix-cls} {
    &-text {
      padding: 20px 0;
    }
  }
}
