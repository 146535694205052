[class^="icon-"],
[class*=" icon-"] {
  @include icon-font();
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  text-decoration: inherit;
  display: inline-block;
  speak: none;
}

a {
  [class^="icon-"],
  [class*=" icon-"] {
    display: inline;
  }
}
