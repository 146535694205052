.upload-file-wrap  {
  position: relative;
  width: 100%;
  height: 100%;
  input[type="file"], .upload-disabled {
    display: block;
    position: absolute;
    margin: 0;
    opacity: 0;
    direction: ltr;
    cursor: pointer;
    z-index: 99;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.upload-answer {
  .btn-warning {
    padding: .266667rem .426667rem;
    font-size: .48rem;
    line-height: 1.3333333;
    border-radius: 0.106667rem;
  }
}

.image-picker, .video-picker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -0.1rem;
}

.image-picker-item, .video-picker-item {
  position: relative;
  width: 2.666667rem;
  height: 2.666667rem;
  margin: 0.1rem;
  img {
    width: 100%;
    object-fit: cover;
  }
  .btn-paint {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1.706667rem;
    height: 0.586667rem;
    text-align: center;
    line-height: 0.586667rem;
    background: #08A6F0;
    border-radius: 3px 0 3px 0;
    color: #FFF;
  }
  .video-player-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.08rem;
    background-size: cover;
    text-align: center;
    background-color: transparent;
    .icon-player {
      color: #FFF;
      font-size: 0.64rem;
    }
  }
  .btn-delete {
    width: .5rem;
    height: .5rem;
    line-height: .5rem;
    background: #fff;
    position: absolute;
    right: -0.133333rem;
    top: -0.133333rem;
    z-index: 10;
    color: #FF2323;
    .iconfont{
      display: inline-block;
      position: absolute;
      top:0;
      left:0;
      color: #2D8DFF;
      font-size: .45rem;
    }
  }
}

.image-picker-item-content, .video-picker-item-content {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  background-size: cover;
  overflow: hidden;
}

.image-picker-upload-btn {
  border: 0.026667rem dashed #9496AB;
  border-radius: 0.133333rem;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  .iconfont {
    color: #B2B4C7;
    font-size: 30px;
  }
  .upload-file-wrap {
    position: absolute;
  }
}
