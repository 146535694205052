// 主色
$theme-color-1: #42487c;
$theme-color-1-1: #4F5BC4;
$theme-color-2: #844d7d;
$theme-color-2-1: #844d7d;
$theme-color-3: #4f814a;
$theme-color-3-1: #4f814a;
$theme-color-4: #8c4545;
$theme-color-4-1: #8c4545;
$theme-color-5: #8c4545;
$theme-color-5-1: #8c4545;

//body 背景色
$bg-theme-e4e5ea: #e4e5ea;

//student 主色
$stu-theme-color-1: #2D8DFF;

// 全局/品牌色
$brand-primary: #2D8DFF;
$brand-primary-tap: #0e80d2;
$brand-success: #6abf47;
$brand-warning: #ffc600;
$brand-error: #f4333c;
$brand-important: #ff5b05; // 用于小红点
$brand-wait: #108ee9;
$primary-color: #2D8DFF;
// 色彩
// ---
// 文字色
$color-text-base: #000; // 基本
$color-text-base-inverse: #fff; // 基本 - 反色
$color-text-secondary: #a4a9b0; // 辅助色
$color-text-placeholder: #bbb; // 文本框提示
$color-text-disabled: #bbb; // 失效
$color-text-caption: #888; // 辅助描述
$color-text-paragraph: #333; // 段落
$color-link: $brand-primary; // 链接

// 字体尺寸
// ---
$font-size-icontext: 0.266667rem;
$font-size-base: 0.373333rem;
$font-size-subhead: 0.4rem;
$font-size-caption: 0.426667rem;
$font-size-heading: 0.453333rem;

// toast
$toast-fill: rgba(58, 58, 58, 0.9); // toast, activity-indicator 的背景颜色

// z-index
$progress-zindex: 2000;
$popover-zindex: 1999;
$toast-zindex: 1999;
$action-sheet-zindex: 1000; // actonsheet 会放到 popup / modal 中
$picker-zindex: 1000;
$popup-zindex: 999;
$modal-zindex: 999; // modal.alert 应该最大，其他应该较小
$tabs-pagination-zindex: 999;
$zindex-affix: 110;

// 圆角
// ---
$radius-xs: 0.053333rem;
$radius-sm: 0.08rem;
$radius-md: 0.133333rem;
$radius-lg: 0.186667rem;
$radius-circle: 50%;

// 边框尺寸
// ---
$border-width-sm: 1px;
$border-width-md: 1px;
$border-width-lg: 2px;

// 间距
// ---
// 水平间距
$h-spacing-sm: 0.133333rem;
$h-spacing-md: 0.213333rem;
$h-spacing-lg: 0.4rem;

// 垂直间距
$v-spacing-xs: 0.08rem;
$v-spacing-sm: 0.16rem;
$v-spacing-md: 0.24rem;
$v-spacing-lg: 0.4rem;
$v-spacing-xl: 0.56rem;

// 图标尺寸
// ---
$icon-size-xxs: 0.4rem;
$icon-size-xs: 0.48rem;
$icon-size-sm: 0.56rem;
$icon-size-md: 0.586667rem; // 导航条上的图标、grid的图标大小
$icon-size-lg: 0.96rem;

// 高度
// ---
$line-height-base: 1; // 单行行高
$line-height-paragraph: 1.5; // 多行行高

// 背景色
$fill-base: #fff; // 组件默认背景
$fill-body: #f5f5f9; // 页面背景
$fill-tap: #ddd; // 组件默认背景 - 按下
$fill-disabled: #ddd; // 通用失效背景
$fill-mask: rgba(0, 0, 0, 0.4); // 遮罩背景
$color-icon-base: #ccc; // 许多小图标的背景，比如一些小圆点，加减号
$fill-grey: #f7f7f7;

// modal
$modal-font-size-heading: 0.48rem;
$modal-button-font-size: 0.48rem; // 按钮字号
$modal-button-height: 1.333333rem; // 按钮高度

// Animation
$animation-duration-slow: .3s; // ModalTemp
$animation-duration-base: .2s;
$animation-duration-fast: .1s; // Tooltip

$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
