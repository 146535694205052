.skeleton {
  display: table;
  width: 100%;
  &-content {
    display: table-cell;
    vertical-align: top;
    width: 100%;
  }
  &-title {
    margin-top: 0.426667rem;
    height: 0.426667rem;
    width: 100%;
    background: #f2f2f2;
  }
}
